<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="arrow-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1>Adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute;</h1>

		<h1>1. Adatkezelő adatai</h1>

		<table>
			<tbody>
			<tr>
				<td>Adatkezelő megnevez&eacute;se:</td>
				<td><strong>PilotNet Korlátolt Felelősségű Társaság</strong></td>
			</tr>
			<tr>
				<td>Sz&eacute;khely:</td>
				<td><strong>2175 Kálló, Petőfi út 2.</strong></td>
			</tr>
			<tr>
				<td>Telefon:</td>
				<td><a href="tel:+36206152055"><strong>+36 20 615 20 55</strong></a></td>
			</tr>
			<tr>
				<td>Email:</td>
				<td><a href="mailto:info@pilotnet.hu"><strong>info@pilotnet.hu</strong></a></td>
			</tr>
			<tr>
				<td>Honlap:</td>
				<td><a href="https://www.pilotnet.hu" target="_blank"><strong>www.pilotnet.hu</strong></a></td>
			</tr>
			<tr>
				<td>C&eacute;gjegyz&eacute;k sz&aacute;m:</td>
				<td><strong>13-09-227621</strong></td>
			</tr>
			<tr>
				<td>K&eacute;pviselője:</td>
				<td><strong>Diófási Áron</strong></td>
			</tr>
			</tbody>
		</table>

		<p></p>

		<h1>2. A tájékoztató célja</h1>
		<p>Jelen adatkezelési tájékoztató célja, hogy az érintettek számára közérthető formában és áttekinthető módon információt biztosítson személyes adataik kezelésével kapcsolatban.</p>
		<p>Az adatkezelés nem tartozik fő tevékenységeink sorába, de annak vitelében különös tekintettel vagyunk a vonatkozó európai uniós és hazai jogi szabályozásra, különösképpen az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016. április 27.) „A természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános adatkezelési rendelet - GDPR)” valamint a hazai „Az információs önrendelkezési jogról és az információszabadságról” szóló 2011. évi CXII. törvény (Infotv.) előírásaira.</p>
		<p>Szakmai tevékenységünket illetően cégünk térkép alapon a légiforgalom megjelenítésével és követésével, valamint GPS alapú repülési információk szolgáltatásával foglalkozik, a PilotNet mobil vagy webes applikációjának felhasználói számára. PilotNet Pro regisztrált felhasználók esetén azon adatok tárolására és rendszerezésére kerül sor, mely adatokat a felhasználó a rendszerbe feltölt - repülések adatai, repülési okmányok. Ezen tevékenységekre a továbbiakban “Szolgáltatások”-ként hivatkozunk.</p>

		<h1>3. Az adatkezelés általános célja</h1>
		<p>A PilotNet applikáció adatgyűjtésének és adatfeldolgozásának célja:</p>
		<ol>
			<li>A “Szolgáltatás” biztosítása, például légi forgalom megjelenítése és a repülés közbeni helyzettudatosság segítése</li>
			<li>Számlák és számviteli bizonylatok kiállítása</li>
			<li>A “Szolgáltatás” színvonalának és minőségének biztosítása, fenntartása és fejlesztése, új funkciók implementálása a begyűjtött adatok és visszajelzések alapján</li>
			<li>Új “Szolgáltatások” fejlesztése</li>
			<li>A “Szolgáltatás” használati módjainak feltérképezése, melynek alapján a “Szolgáltatás” jobbá tétele lehetségessé válhat</li>
		</ol>

		<h1>4. Kezelt adatok</h1>
		<h2>4.1 Applikáció letöltése és használata</h2>
		<p>A <a href="https://pro.pilotnet.hu" target="_blank">https://pro.pilotnet.hu</a>, a <a href="https://pilotnet.hu" target="_blank">https://pilotnet.hu</a> weboldalak és a PilotNet applikáció letöltése, használata</p>
		<p><strong>Érintettek:</strong> minden természetes személy, aki az applikációt letölti, használja.</p>
		<p><strong>Adatkezelés célja:</strong> Szolgáltatás nyújtása</p>
		<table>
			<tr>
				<td>Tevékenység</td>
				<td>Adatfajta</td>
				<td>Jogalap</td>
				<td>Cél</td>
				<td>Őrzési idő</td>
			</tr>
			<tr>
				<td>Szolgáltatás rendelkezésre bocsátása</td>
				<td>
					<ul>
						<li>Email cím</li>
						<li>Telefonszám</li>
						<li>Tartózkodási hely</li>
						<li>Repülések útvonala</li>
						<li>Egyéb, felhasználó által megadott adatok</li>
					</ul>
				</td>
				<td>Szerződés teljesítése</td>
				<td>Szolgáltatás nyújtása</td>
				<td>A jogviszony megszűntetésétől számított 5 évig</td>
			</tr>
		</table>
		<p>Az eszköz típusától függően amire a “Szolgáltatás” telepítésre került, a PilotNet applikáció különböző jogosultságokat kérhet az felhasználó eszközén.</p>
		<p>Ezen jogosultságok megadása kötelező az applikáció működésének biztosításához. A jogosultságokat a felhasználó bármikor elveheti az adott eszköz beállítási útmutatójának és használati utasításának megfelelően.</p>
		<p>A jogosultságok kezelése eszközfüggő, és emiatt eltérő lehet.</p>
		<p>A “Szolgáltatás” használata esetén a felhasználás módjától függően az applikáció hozzáférést kérhet a felhasználó email címéhez, felhasználó applikációban megadott adataihoz, a használt eszköz pontos helyzetéhez, repülési útvonalhoz, repülés adataihoz, okmányokhoz, azokhoz tartozó lejárati időkhöz, az eszköz IP címéhez és egyéb eszköz által szolgáltatott információkhoz és ezen információk tárolásra kerülhetnek a tárolt útvonalhoz kapcsoltan.</p>
		<p>A repülések, melyek során a felhasználó a “Szolgáltatást” használta, rögzítésre kerül a rendszerben.</p>
		<p>A PilotNet applikáció rögzíti az információkat amiknek megosztásához hozzájárult a felhasználó, mint például:</p>
		<ul>
			<li>Telefonszám, repülőgép adatok, útvonaltervek és célrepülőterek, repülések időpontja</li>
			<li>A felhasználó pilótaként vagy utasként végzett utazásait</li>
			<li>Továbbá olyan információkat, amiket a felhasználó a PilotNet vagy PilotNet Pro rendszerbe feltölt, vagy a supporttal megoszt</li>
			<li>Meta adatok (eszköz-, böngésző- és az applikáció használatának adatai)</li>
		</ul>
		<p>A PilotNet applikáció adatokat gyűjthet és rögzíthet a “Szolgáltatás” használatáról, az eszközről, amelyre az applikáció telepítésre került és a böngészőről, amelyen a “Szolgáltatást” használják. Például:</p>
		<ul>
			<li>hogy milyen gyakran, és mennyi ideig van a “Szolgáltatás” használatban, a használt eszköz típusáról, az operációs rendszer típusáról és verziójáról, az akkumulátor használatáról, a kiszolgáló domain és IP címéről amelyen keresztül a “Szolgáltatást” használják, a használt eszköz pontos helyadatairől</li>
			<li>A felhasználó eszköze és a “Szolgáltatás” közötti adatforgalomról, beleértve a crash riportokat, amelyek tartalmazhatják az eszköz teljesítmény és használati adatait időpontokkal ellátva</li>
		</ul>
		<p><strong>Az adatok továbbítása, átadása, adatfeldolgozó igénybevétele</strong></p>
		<p>A szolgáltatás ellenértékének megfizetését a Szolgáltató az OTP Mobil Kft. által biztosított SimplePay szolgáltatása keretében valósítja meg.</p>
		<p>Az ügyfél a szolgáltatás használatával tudomásul veszi, hogy a PilotNet Korlátolt Felelősségű Társaság, mint adatkezelő által a PilotNet mobil applikáció és a <a href="https://pro.pilotnet.hu" target="_blank">https://pro.pilotnet.hu</a> weboldal felhasználói adatbázisában tárolt alábbi személyes adatai átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbiak:</p>
		<ul>
			<li>Számlázási név</li>
			<li>E-mail cím</li>
			<li>Cím</li>
			<li>Telefonszám</li>
		</ul>
		<p>Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a <a href="https://simplepay.hu/adatkezelesi-tajekoztatok/" target="_blank">SimplePay Adatkezelési tájékoztatóban</a> tekinthető meg.</p>
		<p><strong>SimplePay kártya regisztráció</strong></p>
		<p>Az ismétlődő bankkártyás fizetés (továbbiakban: „Ismétlődő fizetés”) egy, a SimplePay által biztosított bankkártya elfogadáshoz tartozó funkció, mely azt jelenti, hogy a Vásárló által a regisztrációs tranzakció során megadott bankkártyaadatokkal a jövőben újabb fizetéseket lehet kezdeményezni a bankkártyaadatok újbóli megadása nélkül.</p>
		<p>Az ismétlődő fizetés ún. „eseti hozzájárulásos” típusa minden tranzakció esetében a Vevő eseti jóváhagyásával történik, tehát, Ön valamennyi jövőbeni fizetésnél jóvá kell, hogy hagyja a tranzakciót.</p>
		<p>A sikeres fizetés tényéről Ön minden esetben a hagyományos bankkártyás fizetéssel megegyező csatornákon keresztül értesítést kap.</p>
		<p>Az Ismétlődő fizetés igénybevételéhez jelen nyilatkozat elfogadásával Ön hozzájárul, hogy a sikeres regisztrációs tranzakciót követően a PilotNet Applikációban és a <a href="https://pro.pilotnet.hu" target="_blank">https://pro.pilotnet.hu</a> weboldalon Ön az itt található felhasználói fiókjából kezdeményezett későbbi fizetések a bankkártyaadatok újbóli megadása nélkül menjenek végbe.</p>
		<p><strong>Figyelem(!): a bankkártyaadatok kezelése a kártyatársasági szabályoknak megfelelően történik.</strong></p>
		<p>A bankkártya adatokhoz sem a Kereskedő, sem a SimplePay nem fér hozzá. A Kereskedő által tévesen vagy jogtalanul kezdeményezett ismétlődő fizetéses tranzakciókért közvetlenül a Kereskedő felel, Kereskedő fizetési szolgáltatójával (SimplePay) szemben bármilyen igényérvényesítés kizárt.</p>
		<p><strong>A szolgáltatás igénybevételével egyidejűleg az ügyfél nyilatkozik, hogy az adatkezelési tájékoztatót átolvasta, annak tartalmát tudomásul vette és elfogadta.</strong></p>

		<h2>4.2 PilotNet Pro - Számlázás</h2>
		<p><strong>Érintettek:</strong> minden felhasználó, aki igénybe veszi a szolgáltatást.</p>
		<p><strong>Adatkezelés célja:</strong> Számviteli törvény szerinti bizonylat kiállítása.</p>
		<table>
			<tr>
				<td>Tevékenység</td>
				<td>Adatfajta</td>
				<td>Jogalap</td>
				<td>Cél</td>
				<td>Őrzési idő</td>
			</tr>
			<tr>
				<td>Számlázás</td>
				<td>
					<ul>
						<li>Név</li>
						<li>Cím</li>
					</ul>
				</td>
				<td>Jogi kötelezettség teljesítése</td>
				<td>Számviteli törvény szerinti bizonylat kiállítása</td>
				<td>A mindenkor hatályos számviteli szabályok szerinti őrzési kötelezettség lejártáig.</td>
			</tr>
		</table>
		<p><strong>Adatkezelés folyamata:</strong></p>
		<p>A felhasználó ügyfelek nevét és címét, valamint amennyiben ezt a felhasználó igényli és megadja nem természetes személyre vonatkozó számlázási adatait felhasználjuk a számviteli bizonylat kiállításához. Ezeket az iratokat, illetve adatokat a mindekor hatályos számviteli szabályok szerinti őrzési kötelezettség lejártáig őrizzük meg.</p>
		<p>Az adatok megadása a vonatkozó jogszabályok alapján a kötelező, elmulasztása esetén a szolgáltatás nem vehető igénybe.</p>

		<h1>5. Az adatok biztonsága</h1>
		<p>Technikai és szervezési intézkedésekkel, valamint eljárások kialakításával gondoskodunk az általunk kezelt személyes adatok biztonságáról.</p>
		<p>Az adatokat megfelelő intézkedésekkel védjük a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válás ellen.</p>
		<p>A személyes adatokhoz csak azok a munkatársaink férnek hozzá, akiknek feladataik ellátásához szükségük van azok megismerésére.</p>
		<p>Az adatok biztonsága érdekében</p>
		<ul>
			<li>az informatikai rendszer tervezése és üzemeltetése során felmérjük és figyelembe vesszük a lehetséges kockázatokat, törekedve azok folyamatos csökkentésére</li>
			<li>figyelemmel kísérjük a felmerülő fenyegetéseket és sérülékenységeket (mint pl. számítógépes vírusok, számítógépes betörések, szolgáltatásmegtagadásra vezető támadások stb.), hogy időben intézkedhessünk azok elkerülése, elhárítása érdekében</li>
			<li>az informatikai eszközöket, valamint a papíron kezelt információkat védjük az illetéktelen fizikai hozzáférés, valamint a környezeti hatások (pl. víz, tűz, elektromos túlfeszültség) ellen</li>
			<li>az informatikai rendszerünk megfigyelésével gondoskodunk a lehetséges problémák, események felderítéséről</li>
			<li>nagy gondot fordítunk a munkatársak információbiztonsági képzésére és a tudatosság növelésére</li>
			<li>az üzemeltetésben résztvevő szolgáltatók kiválasztásánál alapvető szempont a megbízhatóság</li>
		</ul>

		<h1>6. Az adatok továbbítása, átadása</h1>
		<p>Az adatok átadására sor kerülhet hatósági megkeresések esetén.</p>
		<p>A PilotNet “Szolgáltatás” a tárolt repülések adatait anoním módon továbbíthatja és értékesítheti harmadik fél számára a kisgépes repülés fejlődése érdekében. Ilyen adatok lehetnek a repült útvonalak adatai, repülések gyakorisága, repülőgép típusa, stb.</p>
		<p>A PilotNet "Szolgáltatás" a felhasználási adatokat anonim módon, analitika és felhasználási módok monitorozása céljából továbbíthatja a Google felé. (<a href="https://policies.google.com/technologies/partner-sites" target="_blank">https://policies.google.com/technologies/partner-sites</a>)</p>
		<p>A számlák kezelésével kapcsolatos tevékenységeinkhez vállalkozásunk adatfeldolgozót (KBOSS.hu Kft., 1031 Budapest, Záhony utca 7/C., Cégjegyzékszám: 01-09-303201) vesz igénybe, ugyanis a szolgáltatásnyújtás ellenértékéről szóló számlát harmadik személy által működtetett, online számlázóprogram (<a href="https://www.szamlazz.hu" target="_blank">https://www.szamlazz.hu</a>) segítségével állítjuk ki. A vele megkötött szerződésünk része az adatfeldolgozásról szóló megállapodás, mely biztosítja, hogy eljárása során ugyanolyan körültekintéssel, a vonatkozó jogszabályok megtartásával, kizárólag az utasításunknak megfelelően végzi az adatok kezelését.</p>

		<h1>7. Személyes adatok megőrzésének ideje</h1>
		<p>A PilotNet a személyes adatokat a “Szolgáltatás” használatának időtartama alatt tárolja. Onnantól, hogy a felhasználó többé nem használja a “Szolgáltatást”, adatai 5 évig tárolva maradhatnak.</p>

		<h1>8. Az érintettek jogai</h1>
		<p><strong>Érintettek:</strong> minden természetes személy, aki az applikációt letölti, használja.</p>
		<p>Minden érintettnek joga van:</p>
		<ul>
			<li>Az őt érintő adatkezelésről annak megkezdését megelőzően tájékoztatást kapjon</li>
			<li>Hozzáférjen az őt érintő adatkezeléssel kapcsolatos minden információhoz</li>
			<li>Hibás, pontatlan, hiányos adatainak helyesbítését kérni</li>
			<li>Személyes adatainak törlését (inaktiválását) kérni</li>
			<li>Az adatkezelés korlátozását kérni</li>
			<li>Tiltakozni adatainak bizonyos esetekben történő, pl. marketing célú felhasználása ellen</li>
			<li>Jogorvoslattal élni az adatkezeléssel szemben</li>
		</ul>
		<p>Ezeket a jogokat az érintettek az alább megadott elérhetőségeink használatával, írásban, illetve előzetes egyeztetés alapján személyesen gyakorolhatják. Minden megkeresésre igyekszünk a lehető legrövidebb időn belül, de legfeljebb 15 munkanapon belül válaszolni.</p>
		<p>Elérhetőségek a joggyakorlás érdekében:</p>
		<ul>
			<li>Postai levélben: PilotNet Korlátolt Felelősségű Társaság, 2100 Gödöllő, Remsey krt. 8.</li>
			<li>E-mailben: info@pilotnet.hu</li>
			<li>Személyesen: +36 20 615 20 55 telefonszámon egyeztetett módon.</li>
		</ul>
		<p>Telefonon személyes adatokkal kapcsolatos információkat nem áll módunkban kiadni, mivel nem tudjuk azonosítani a hívó felet.</p>
		<p>Jogaik megsértése esetén az érintettek a Nemzeti Adatkezelési és Információszabadság Hatósághoz fordulhatnak</p>
		<ul>
			<li>Cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c</li>
			<li>Telefon: +36 (1) 391-1400</li>
			<li>Fax: +36 (1) 391-1410</li>
			<li>Honlap: http://www.naih.hu</li>
			<li>Email: ugyfelszolgalat@naih.hu</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "PrivacyStatementView",
	data() {
		return {
			showBackLink: history.length > 1
		}
	},
	methods: {
		scrollTo(refName) {
			let element = this.$refs[refName];
			let top = element.offsetTop;
			window.scrollTo(0, top);
		}
	}
}
</script>

<style scoped>
table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>
