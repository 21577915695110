<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="arrow-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1>Privacy statement</h1>

		<h1>1. Data Controller&rsquo;s details</h1>

		<table>
			<tbody>
			<tr>
				<td>Name of Data Controller:</td>
				<td><strong>PilotNet Korlátolt Felelősségű Társaság</strong></td>
			</tr>
			<tr>
				<td>Registered office:</td>
				<td><strong>2175 Kálló, Petőfi út 2.</strong></td>
			</tr>
			<tr>
				<td>Phone:</td>
				<td><a href="tel:+36206152055"><strong>+36 20 615 20 55</strong></a></td>
			</tr>
			<tr>
				<td>Email:</td>
				<td><a href="mailto:info@pilotnet.hu"><strong>info@pilotnet.hu</strong></a></td>
			</tr>
			<tr>
				<td>Website:</td>
				<td><a href="https://www.pilotnet.hu" target="_blank"><strong>www.pilotnet.hu</strong></a></td>
			</tr>
			<tr>
				<td>Company registration number:</td>
				<td><strong>13-09-227621</strong></td>
			</tr>
			<tr>
				<td>Representative:</td>
				<td><strong>Diófási Áron</strong></td>
			</tr>
			</tbody>
		</table>

		<p>&nbsp;</p>

		<h1>2. Purpose of the information</h1>
		<p>The purpose of this data processing policy is to provide data subjects with information in a clear and comprehensible way about the processing of their personal data.</p>
		<p>The processing of personal data is not one of our core activities, but in carrying it out we pay particular attention to the relevant EU and national legislation, in particular Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Processing Regulation - GDPR) and the provisions of the domestic Act CXII of 2011 on the Right to Informational Self-Determination and on Freedom of Information.</p>
		<p>Our professional activities involve the display and tracking of air traffic on a map, as well as the provision of GPS-based flight information for users of the PilotNet mobile or web application. For registered PilotNet Pro users, the data uploaded by the user to the system—such as flight data and flight documents—is stored. These activities will hereinafter be referred to as "Services."</p>

		<h1>3. General purpose of data processing</h1>
		<p>The purpose of data collection and processing in the PilotNet application is as follows:</p>
		<ul>
			<li>To provide the “Service,” such as displaying air traffic and supporting situational awareness during flights</li>
			<li>To issue invoices and accounting documents</li>
			<li>To ensure, maintain, and improve the quality and standard of the “Service,” as well as to implement new features based on collected data and feedback</li>
			<li>To develop new “Services”</li>
			<li>To map the ways the “Service” is used, enabling improvements to the “Service”</li>
		</ul>

		<h1>4. Data processed</h1>
		<h2>4.1 Downloading and using the applications</h2>
		<p>Downloading and using the <a href="https://pro.pilotnet.hu" target="_blank">https://pro.pilotnet.hu</a>, and the <a href="https://pilotnet.hu">https://pilotnet.hu</a> website and PilotNet application</p>
		<p><strong>Data subjects:</strong> all natural persons who download and use the application.</p>
		<p><strong>Purpose:</strong> of data processing</p>
		<table>
			<tr>
				<td>Activity</td>
				<td>Data type</td>
				<td>Legal basis</td>
				<td>Purpose</td>
				<td>Retention period</td>
			</tr>
			<tr>
				<td>Provision of the application</td>
				<td>
					<ul>
						<li>Email address</li>
						<li>Phone number</li>
						<li>Location</li>
						<li>Flight routes</li>
						<li>Other information, uploaded by the user</li>
					</ul>
				</td>
				<td>Performance of a contract</td>
				<td>Provision of service</td>
				<td>5 years from the termination of the legal relationship.</td>
			</tr>
		</table>
		<p>Depending on the type of device on which the “Service” is installed, the PilotNet application may request various permissions on the user's device.</p>
		<p>Granting these permissions is mandatory to ensure the proper functioning of the application. Users can revoke permissions at any time in accordance with the device's settings guide and user manual. Permission management varies by device and may differ accordingly.</p>
		<p>When using the “Service,” depending on the mode of use, the application may request access to the user's email address, data provided in the application, the exact location of the device, flight routes, flight data, documents and their expiration dates, the device’s IP address, and other information provided by the device. This information may be stored in connection with the recorded routes.</p>
		<ul>
			<li>Flights during which the user utilized the “Service” are recorded in the system.</li>
			<li>The PilotNet application records the information that the user has agreed to share, such as:</li>
			<li>Phone number, aircraft details, flight plans, and destination airports, as well as flight times</li>
			<li>The user's trips as a pilot or passenger</li>
			<li>Information uploaded by the user to the PilotNet or PilotNet Pro system or shared with support</li>
			<li>Metadata (device, browser, and application usage data)</li>
		</ul>
		<p>The PilotNet application may collect and record data about the use of the “Service,” the device on which the application is installed, and the browser through which the “Service” is accessed. For example:</p>
		<ul>
			<li>Frequency and duration of “Service” usage, device type, operating system type and version, battery usage, server domain and IP address used to access the “Service,” and precise location data of the device</li>
			<li>Data traffic between the user's device and the “Service,” including crash reports, which may contain performance and usage data of the device with timestamps</li>
		</ul>
		<p><strong>Transmission, transfer of data, use of data processor</strong></p>
		<p>The Service Provider shall pay the consideration for the service within the framework of the SimplePay service provided by OTP Mobil Kft.</p>
		<p>By using the service, the customer acknowledges that the following personal data stored by PilotNet Kft. as data controller in the user database of the PilotNet mobile application and the <a href="https://pro.pilotnet.hu" target="_blank">https://pro.pilotnet.hu</a> website will be transferred to OTP Mobil Kft. as data processor. The scope of the data transferred by the data controller is as follows:</p>
		<ul>
			<li>Invoicing name</li>
			<li>E-mail address</li>
			<li>Address</li>
			<li>Telephone number</li>
		</ul>
		<p>The nature and purpose of the data processing activities carried out by the processor can be found in the <a href="https://simplepay.hu/adatkezelesi-tajekoztatok/" target="_blank">SimplePay Data Processing Policy.</a></p>
		<p><strong>SimplePay card registration</strong></p>
		<p>Recurring credit card payment (hereinafter referred to as "Recurring Payment") is a credit card acceptance function provided by SimplePay, which means that the credit card details provided by the Customer during the registration transaction can be used to initiate future payments without having to re-enter the credit card details.</p>
		<p>The recurring payment type is called "occasional consent" and is subject to occasional approval by the Customer for each transaction, i.e., you must approve the transaction for all future payments.</p>
		<p>You will always be notified of a successful payment through the same channels as a traditional credit card payment.</p>
		<p>To use Recurring Payments, by accepting this statement, you agree that following a successful registration transaction, subsequent payments made from your account in the PilotNet Application and on <a href="https://pro.pilotnet.hu" target="_blank">https://pro.pilotnet.hu</a> will be processed without re-entering your credit card details.</p>
		<p><strong>Please note(!): credit card details are processed in accordance with the card company's rules.</strong></p>
		<p>The Merchant and SimplePay have no access to the credit card data. The Merchant is directly liable for recurring payment transactions initiated by the Merchant in error or illegally, and any claims against the payment service provider of the Merchant (SimplePay) are excluded.</p>
		<p><strong>By using the service, the customer declares that he has read, acknowledged and accepted the contents of the data processing policy.</strong></p>

		<h2>4.2 PilotNet Pro - Invoicing</h2>
		<p><strong>Data subjects:</strong> all users who use the sevice.</p>
		<p><strong>Purpose:</strong> issuing a receipt in accordance with the Accounting Law.</p>
		<table>
			<tr>
				<td>Activity</td>
				<td>Data type</td>
				<td>Legal basis</td>
				<td>Purpose</td>
				<td>Retention period</td>
			</tr>
			<tr>
				<td>Invoicing</td>
				<td>
					<ul>
						<li>Name</li>
						<li>Address</li>
					</ul>
				</td>
				<td>Fulfilment of a legal obligation</td>
				<td>To issue a receipt in accordance with the Accounting Act</td>
				<td>Until the expiry of the retention obligation under the accounting rules in force at the time.</td>
			</tr>
		</table>
		<p>Process of data processing:</p>
		<p>The name and address of the user customers and, if requested by the user and provided by the user, invoicing information of a non-natural person will be used to issue the accounting document. These documents or data will be kept until the expiry of the retention period under the accounting rules in force at the time.</p>
		<p>The provision of data is mandatory under the applicable legislation, failing which the service will not be provided.</p>

		<h1>5. Security of data</h1>
		<p>We ensure the security of the personal data we process by implementing technical and organizational measures and procedures.</p>
		<p>We take appropriate measures to protect the data against unauthorized access, alteration, disclosure, disclosure, deletion or destruction, accidental destruction or damage, and against inaccessibility resulting from changes in the technology used.</p>
		<p>Access to personal data is restricted to those employees who need to know it in order to perform their duties.</p>
		<p>To ensure the security of your data:</p>
		<ul>
			<li>we assess and take into account potential risks in the design and operation of the IT system, and seek to mitigate them continuously</li>
			<li>monitor emerging threats and vulnerabilities (such as computer viruses, computer intrusions, denial of service attacks, etc.) to take timely action to avoid and prevent them</li>
			<li>protect IT assets and information on paper against unauthorized physical access and environmental impacts (e.g. water, fire, electrical surges)</li>
			<li>monitor our IT systems to detect potential problems and incidents</li>
			<li>we take great care to train staff in information security and raise awareness</li>
			<li>reliability is a key criterion in the choice of service providers</li>
		</ul>

		<h1>6. Transmission, transfer of data, use of data processor</h1>
		<p>Data may be transferred in the event of a request from a public authority. </p>
		<p>PilotNet only stores location data that are aggregated as anonymized contents, with the aim of helping us and third parties to collect general information about General Aviation (GA) e.g. the evolution of GA activity, by region, time, duration, frequency, trajectories of flights, type of aircraft etc. The resulting data may be shared, sold or licensed to third parties, e.g. for statistical purposes, for instance, to study the impact of General Aviation (GA) or use of airspace as well as to enhance traffic awareness throughout the GA community. </p>
		<p>PilotNet might share anonym data to Google (<a href="https://policies.google.com/technologies/partner-sites" target="_blank">https://policies.google.com/technologies/partner-sites</a>) with the aim of analitics and usage monitoring of our system.</p>
		<p>For our invoice management activities, our company uses a data processor (KBOSS.hu Kft., 1031 Budapest, Záhony utca 7/C., Company Registration No.: 01-09-303201), as the invoice for the consideration for the provision of services is issued by means of an online invoicing program (<a href="https://www.szamlazz.hu" target="_blank">https://www.szamlazz.hu</a>) operated by a third party. The data processing agreement is part of our contract with it, which ensures that it will process the data with the same care, in compliance with the applicable legislation and only in accordance with our instructions.</p>

		<h1>7. Retention time of personal data</h1>
		<p>PilotNet stores personal data for at least as long as we provide “Services” to you. When you decide not to use our “Services” any longer, or when the subscription expires, your data held will be deleted after 5 years.</p>

		<h1>8. Rights of data subjects</h1>
		<p><strong>Data subjects:</strong> all users who use the sevice.</p>
		<p>All data subjects have the right to:</p>
		<ul>
			<li>To be informed of the data processing concerning him or her before they start</li>
			<li>Have access to all information about data processing concerning him or her</li>
			<li>Request the correction of incorrect, inaccurate or incomplete data</li>
			<li>Request the deletion (inactivation) of his/her personal data</li>
			<li>Request restriction of data processing</li>
			<li>Object to the use of his/her data in certain cases, for example for marketing purposes</li>
			<li>To exercise remedy against processing of personal data</li>
		</ul>
		<p>These rights can be exercised by the data subject in writing using the contact details provided below, or in person by prior arrangement. We will endeavour to respond to all requests as soon as possible, but not later than 15 working days.</p>
		<p>Contact details for exercising your rights:</p>
		<ul>
			<li>By post: PilotNet Kft., 2100 Gödöllő, Remsey krt. 8.</li>
			<li>By e-mail: info@pilotnet.hu</li>
			<li>In person: +36 20 615 20 55 by telephone as agreed.</li>
		</ul>
		<p>We are unable to give out personal information via a phone call as we cannot identify the caller.</p>
		<p>In the event of a breach of their rights, data subjects may refer the matter to the National Authority for Data Protection and Freedom of Information:</p>
		<ul>
			<li>Address: 1125 Budapest, Szilágyi Erzsébet fasor 22/c</li>
			<li>Phone: +36 (1) 391-1400</li>
			<li>Fax: +36 (1) 391-1410</li>
			<li>Website: https://www.naih.hu</li>
			<li>Email: ugyfelszolgalat@naih.hu</li>
		</ul>

	</div>
</template>

<script>
export default {
	name: "PrivacyStatementView",
	data() {
		return {
			showBackLink: history.length > 1
		}
	},
	methods: {
		scrollTo(refName) {
			let element = this.$refs[refName];
			let top = element.offsetTop;
			window.scrollTo(0, top);
		}
	}
}
</script>

<style scoped>
table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>
