<template>
	<div id="app">
		<FrontPageAd v-if="visibleAd" @allClosed="allFrontPageAdClosed()"/>
		<div class="sideMenuArea" v-if="shouldShowMenu">
			<MainMenu @logout="logout" @click="closeMenu" @noConnection="visiblePopUp = true"/>
		</div>

		<div class="d-flex flex-grow-1 flex-column mainArea"
			:class="{ 'mainAreaOpened': showMenu, 'mainAreaOpenedRight': showRightMenu && !staticRightMenu, 'mainAreaClosedRight': !showRightMenu }"
			:style="{ 'overflow-y': 'auto', 'overscroll-behavior-y': 'none' }"
			>
			<div class="mainAreaOverlay" :class="{ 'mainAreaOverlayOpened': showMenu || showRightMenu && !staticRightMenu }"
				@click="closeMenu" style="text-align: right;"></div>


			<nav class="site-nav navbar navbar-dark bg-dark sticky-top" :class="navClass">
				<div class="d-flex w-100 no-gutters">
					<div class="align-self-start" v-if="shouldShowMenu">
						<button class="navbar-toggler" type="button" :aria-label="$t('menu.open')" @click="toggleMenu">
							<span class="navbar-toggler-icon"></span>
						</button>
					</div>
					<AppStoreBadges v-if="showStoreBadges" :is-iframe="!shouldShowMenu" />
					<div class="flex-grow-1 mx-2 text-center navbar-brand-container">
						<a href="https://pilotnet.hu" target="_blank" class="navbar-brand">
							<logo class="d-inline-block mr-2" v-if="navClass && elapsedTime" width="35px" :onlyIcon="true" />
							<logo v-else />

							<span v-if="navClass && elapsedTime">{{ elapsedTime }}</span>
							<!-- <span v-else>PilotNet</span> -->
						</a>
					</div>

					<div class="d-flex align-items-center mr-3">
						<help-button :helpName="$route.name" v-if="shouldShowMenu" />
					</div>
					<div class="lang-flag-con mr-3 d-flex align-items-center">
						<a href="javascript:void(0)" @click="changeLang('en')" v-if="$i18n.locale != 'en' && shouldShowMenu">
							<img src="@/assets/flags/en.png" class="lang-flag" />
						</a>
						<a href="javascript:void(0)" @click="changeLang('hu')" v-if="$i18n.locale != 'hu' && shouldShowMenu">
							<img src="@/assets/flags/hu.png" class="lang-flag" />
						</a>
					</div>
					<div class="align-self-end">
						<span class="navbar-brand mr-0" v-on:click="toggleRightMenu(!showRightMenu)" v-if="enabledRightMenu">
							<font-awesome-icon icon="cog" size="lg" />
						</span>
					</div>

				</div>


			</nav>

			<main class="flex-grow-1 container d-flex flex-column" :class="{ 'fullscreen': fullscreen }">
				<Messages :messages="messages" :fullscreen="fullscreen" />

				<router-view :msgs="componentMessages" :loggedInUserData="loggedInUserData" :app="this" ref="routerView"
					@loginCallback="loginCallback" @logoutNoRest="logoutNoRest" @googleLogin="googleLogin"
					@reloadUser="reloadUser" @addMessage="addMessage" @logout="logout(true)"/>
			</main>

		</div>
		<div class="rightSideMenu" :class="showRightMenu ? 'rightSideMenuOpened' : ''" v-if="enabledRightMenu">
			<div class="row w-100 no-gutters">
				<div class="col-12" v-for="rightMenu in $rightMenu" :key="rightMenu.props.title">
					<component :is="rightMenu.component" v-bind:data="rightMenu.props"></component>
				</div>
			</div>
		</div>
<!--		<AjaxOverlay v-if="showAjaxOverlay || showMikrotikOverlay" />-->
	<ConstentPopup @close="e => showCookieConsent = !showCookieConsent" v-if="showCookieConsent"  />

		<Dialog position="top" :header="$t('menu.noInternet')" :visible.sync="visiblePopUp">
			<p style="white-space: pre-wrap">{{$t('menu.noInternetDescription')}}</p>
			<template #footer>
				<Button @click="visiblePopUp=false">
					{{$t('menu.ok')}}
				</Button>
			</template>
		</Dialog>
	</div>
</template>

<script>
import AjaxOverlay from '@/components/AjaxOverlay.vue'
import MainMenu from '@/components/menu/MainMenu.vue'
import Messages from '@/components/Messages.vue'

import $ from 'jquery'
import Vue from "vue";
import helpmenumanager from './plugins/helpmenumanager';
import { LOCAL_STORAGE_SERVICE, READ_JSON_FILE, STORAGE_KEY } from "./plugins/utils";
import HelpButton from './components/help/HelpButton.vue'
import Logo from './components/Logo.vue'
import AppStoreBadges from "@/components/AppStoreBadges.vue";
import versionConfig from '../version.config'
import { v4 } from "uuid";
import ConstentPopup from "@/components/ConsentPopup.vue";
import eventqueue from "@/plugins/eventqueue";
import FrontPageAd from "@/components/frontPageAd/FrontPageAd.vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

const RIGHT_MENU_ENABLED_IN = ['route-planning-element', 'map', 'flying-map', 'flight-observer'];
export default {
	name: 'App',
	data() {
		return {
			visibleAd: false,
			visiblePopUp: false,
			elapsedTime: null,
			messages: [],
			componentMessages: {},
			loggedInUserData: null,
			requestedRoute: null,
			fullscreen: false,
			showAjaxOverlay: false,
			showMikrotikOverlay: false,
			showMenu: false,
			showCookieConsent: false,
			networkError: false,
			enabledRightMenu: RIGHT_MENU_ENABLED_IN.includes(this.$route.name),
			showRightMenu: false,
			staticRightMenu: false,
			windowSize: ``,
			checkInTimeout: null,
		};
	},
	computed: {
		shouldShowMenu() {
			return !window.inIframe;
		},
		showStoreBadges() {
			return window.platform == "web";
		},
		pageName() {
			let t = this.$t('menu.siteName');
			if (this.$route && this.$route.meta && this.$route.meta.title) {
				t += ' - ' + this.$t(this.$route.meta.title);
			}
			return t;
		},
		navClass() {
			if (this.$route && this.$route.name == 'flying-map') {
				if (this.$store.getters.getNoInternet) {
					return 'nav-offline';
				}
				return 'nav-online';
			}
			return '';
		},
		mobile() {
      return 'ios' === window.platform || 'android' === window.platform;
    },
	},
	methods: {
		setElapsedTimer() {
			const startTimeRaw = window.consentedStorage.getItem('currentRouteStart');
			if (!startTimeRaw) {
				this.elapsedTime = null;
				return;
			}

			const elapsed = new Date(new Date().getTime() - parseFloat(startTimeRaw));
			if (!elapsed) {
				this.elapsedTime = null;
				return;
			}


			this.elapsedTime = `${elapsed.getUTCHours().toFixed().padStart(2, '0')}:${elapsed.getUTCMinutes().toFixed().padStart(2, '0')}:${elapsed.getUTCSeconds().toFixed().padStart(2, '0')}`
		},
		startElapsedTimer() {
			this.setElapsedTimer();
			setInterval(() => {
				this.setElapsedTimer();
			}, 1000)
		},
		toggleRightMenu(showRightMenu, staticRightMenu) {
			if (showRightMenu) {
				$('#app').addClass('rightMenuOpened');
			}
			else {
				setTimeout(() => $('#app').removeClass('rightMenuOpened'), 500);
			}

			setTimeout(() => {
				this.showRightMenu = showRightMenu;
				if (staticRightMenu !== undefined) {
					this.staticRightMenu = staticRightMenu;
				}
			}, 1);
		},
		toggleMenu() {
			if (this.showMenu) {
				this.closeMenu();
			} else {
				this.showMenu = true;
				$('#app').addClass('menuOpened');
			}
		},
		closeMenu() {
			if (this.showRightMenu && !this.staticRightMenu) {
				this.showRightMenu = false;
				this.toggleRightMenu(false);
				return;
			}

			this.showMenu = false;
			setTimeout(() => $('#app').removeClass('menuOpened'), 500);
		},
		navigateTo(to) {
			if (this.$router.currentRoute.name != to) {
				this.$router.push(to);
			}
		},
		/**
		 * @param {('en'|'hu')} lang
		 */
		changeLang(lang) {
			this.$i18n.locale = lang;
			window.consentedStorage.setItem('Language', lang);

			if (this.$route.meta) {
				if (this.$route.meta.title) {
					document.title = this.$t('pageTitle.base') + ' - ' + this.$t(this.$route.meta.title);
				} else {
					document.title = this.$t('pageTitle.base');
				}
			}

			if (lang == 'hu') {
				if (this.$route.name == 'privacy-statement') {
					this.$router.replace('/privacy-statement-hu');
				} else if (this.$route.name == 'gtc') {
					this.$router.replace('/gtc-hu');
				}
			} else {
				if (this.$route.name == 'privacy-statement-hu') {
					this.$router.replace('/privacy-statement');
				} else if (this.$route.name == 'gtc-hu') {
					this.$router.replace('/gtc');
				}
			}
		},
		addMessage(messageObj) {
			if (!messageObj.component) {
				this.messages.push({
					id: this.messages.length,
					text: messageObj.messageKey ? this.$t(messageObj.messageKey, messageObj.params) : messageObj.message,
					severity: messageObj.severity || 'info'
				});


			} else {
				let l = this.componentMessages[messageObj.component];
				if (!l) {
					l = [];
					this.$set(this.componentMessages, messageObj.component, l);
				}
				l.push({
					id: l.length,
					text: messageObj.messageKey ? this.$t(messageObj.messageKey, messageObj.params) : messageObj.message,
					severity: messageObj.severity || 'info'
				});
			}
		},
		addMessages(messages) {
			for (let i = 0; i < messages.length; i++) {
				this.addMessage(messages[i]);
			}
		},
		clearMessages() {
			this.messages = [];
			this.componentMessages = {};
		},
		async googleLogin(token) {
			const resp = await this.$rest.googleLogin(token);
			if(resp) {
				this.loginCallback(resp);
			}
		},
		deleteAppleStuff() {
			window.consentedStorage.removeItem('appleUser');
			window.consentedStorage.removeItem('appleName');
			window.consentedStorage.removeItem('appleGivenName');
			window.consentedStorage.removeItem('appleFamilyName');
			window.consentedStorage.removeItem('appleEmail');
		},
		async appleLogin(jsonStr) {
			let json = $.parseJSON(jsonStr);

			if (json.email) {
				window.consentedStorage.setItem('appleUser', json.user);
				window.consentedStorage.setItem('appleName', json.name);
				window.consentedStorage.setItem('appleGivenName', json.given_name);
				window.consentedStorage.setItem('appleFamilyName', json.family_name);
				window.consentedStorage.setItem('appleEmail', json.email);
			} else {
				let savedAppleUserId = window.consentedStorage.getItem('appleUser');
				if (json.user === savedAppleUserId) {
					json.name = window.consentedStorage.getItem('appleName');
					json.given_name = window.consentedStorage.getItem('appleGivenName');
					json.family_name = window.consentedStorage.getItem('appleFamilyName');
					json.email = window.consentedStorage.getItem('appleEmail');
				}
			}


			const resp = await this.$rest.appleLogin({
				userId: json.user,
				name: json.name,
				givenName: json.given_name,
				familyName: json.family_name,
				email: json.email
			});

			this.deleteAppleStuff();
			this.loginCallback(resp);
		},

		/**
		 *
		 * @param {UserToken} ut
		 * @param {((ut: UserToken) => void)} loginCompleted?
		 */
		async loginCallback(ut, loginCompleted = () => {}) {
			this.$store.dispatch('storeLoggedInEmail', ut.user.email)
			this.$store.dispatch('storeLoggedInUserToken', ut.token)
			const token = ut.token;
			if (token && window.platform !== 'web') {
				window.loadAppLink('r2gflightserver://refetch-files?token=' + token);
			}
			window.loadAppLink(`r2gflightserver://user-data?email=${ut.user.email}&token=${ut.token}&username=${ut.user.username}`);
			this.setLoggedInUserData(ut.user)

			window.newEvent("LOGIN")

			if (typeof loginCompleted === 'function') {
				loginCompleted(this.loggedInUserData)
			}
			await this.navigateForward();
		},

		/**
		 * @param {User} loggedInUserData
		 */
		setLoggedInUserData(loggedInUserData) {
			this.loggedInUserData = loggedInUserData;
			this.$store.dispatch('storeLoggedInUserData', loggedInUserData)
		},

		async handleFrontPageAd() {
			const adClosed = localStorage.getItem('frontPageAdClosed');
			const frontPageAdTimeout = await this.$rest.getFrontPageAdTimeout();
			if (!window.inIframe && !window.location.href.includes("/login") && (!adClosed || new Date(new Date(adClosed).getTime() + frontPageAdTimeout * 60 * 1000) < new Date())) {
				this.visibleAd = true;
				localStorage.removeItem('frontPageAdClosed');
			}
		},

		async navigateForward() {
			if (this.requestedRoute && this.requestedRoute.meta && this.requestedRoute.meta.canRedirectHereAfterLogin) {
				this.$router.replace(this.requestedRoute);
				this.requestedRoute = null;
			} else {
				if(this.$route.query.redirectTo) {
					window.location.href = "#" + this.$route.query.redirectTo
				}
				else if (('ios' === window.platform || 'android' === window.platform) && this.$store.state.loggedInUserToken) {
					this.$router.replace('/start-flight');
				}
				else if(!this.$route.query.oauth_id) {
					this.$router.replace({ name: 'map' });
				}
			}
			await this.handleFrontPageAd();
		},

		/**
		 * @param {(user: User) => void} callback
		 */
		async reloadUser(callback) {
			const data = await this.$rest.getLoggedInUser();
			if(!data) {
				this.logoutNoRest();
				return;
			}
			this.setLoggedInUserData(data);
			if (typeof callback === 'function') {
				callback(this.loggedInUserData);
			}
		},

		logoutNoRest() {
			window.consentedStorage.removeItem('loggedInEmail');
			window.consentedStorage.removeItem('loggedInUserToken');
			window.consentedStorage.removeItem('Language');
			window.consentedStorage.removeItem('notes');
			this.setLoggedInUserData(null);
			window.loadAppLink(`r2gflightserver://user-data?logout=1`);

			if ('ios' === window.platform || 'android' === window.platform) {
				window.loadAppLink('r2gflightserver://google-logout');
			}

			if ('ios' === window.platform || 'android' === window.platform) {
				window.loadAppLink('r2gflightserver://facebook-logout');
			}

			window.newEvent("LOGOUT")
		},

		async logout(forced = false, redirect = true) {
			if(!forced) {
				if (!await this.$confirm(this.$t('logout-confirm'))) {
					return;
				}
			}
			const success =  await this.$rest.logout(forced);
			console.log('logout');
			this.logoutNoRest();

			if(!success) {
				return;
			}

			if (this.$router.currentRoute.meta && this.$router.currentRoute.meta['loginRequired'] && redirect) {
				this._setRequestedRoute(this.$router.currentRoute);
				if (!this.requestedRoute || this.requestedRoute.name !== 'login') {
					await this.$router.replace('login');
				}
			}
		},
		_setRequestedRoute(route) {
			if (this.loggedInUserData) {
				this.requestedRoute = null;
			} else if (route && route.meta) {
				if (route.meta['canRedirectHereAfterLogin']) {
					this.requestedRoute = route;
				} else if (route.name !== 'login') {
					this.requestedRoute = null;
				}
			}
		},
		/**
		 * @param {import("vue-router").Route} to
		 */
		onRouteChangeStuff(to) {
			if (!to) {
				return;
			}

			this.newEvent("PAGE_VIEW", {
				name: to.name,
				path: to.path,
				fullPath: to.fullPath,
				params: to.params ? JSON.stringify(to.params) : null,
				query: to.query ? JSON.stringify(to.query) : null,
			})

			$('#app').removeClass('rightMenuOpened');

			this._setRequestedRoute(to);

			if (to.meta) {

				if (to.meta.loginRequired && !this.loggedInUserData) {
					this.$router.replace({name: 'login', query: {token: this.$route.query.token, redirectTo: to.fullPath}});
					setTimeout(() => {
						this.addMessage({ messageKey: 'error.needsLogin', severity: 'error' })
					}, 0);
					return;
				}

			}

			if (to.meta) {
				if (to.meta.title) {
					document.title = this.$t('pageTitle.base') + ' - ' + this.$t(to.meta.title);
				} else {
					document.title = this.$t('pageTitle.base');
				}

				this.fullscreen = to.meta.fullscreen === true;

				if (to.meta.redirectTo) {
					this.$router.replace(to.meta.redirectTo);
				}
			}
		},
		feedLocation(lat, lng, alt, course, speed, time) {
			this.$store.dispatch('storeCurrentGPS', { lat: lat, lng: lng, alt: alt, course: course, speed: speed, time: time });
		},

		/** @param {('DISABLED'|'FOREGROUND'|'BACKGROUND')} permission */
		feedGpsPermission(permission) {
			this.$store.dispatch('storeGpsPermission', permission);
		},
		feedStartLocation(lat, lng, alt, course, speed) {
			this.$store.dispatch('storeStartGPS', { lat: lat, lng: lng, alt: alt, course: course, speed: speed });
		},
		feedFcmToken(fcmToken) {
			window.consentedStorage.setItem('fcmToken', fcmToken);
		},
		feedDevice(deviceId, deviceName, deviceBrand, osName, appVersion, fcmToken) {
			window.deviceId = deviceId;
			window.deviceName = deviceName;
			window.appVersion = appVersion;
			window.deviceBrand = deviceBrand;
			window.osName = osName;
			window.consentedStorage.setItem('deviceId', deviceId);
			window.consentedStorage.setItem('fcmToken', fcmToken);

			if(this.checkInTimeout) {
				clearTimeout(this.checkInTimeout);
				window.checkedIn = true;
			}
		},

		scrollUp() {
			let scrollTop = $(document).scrollTop();
			if (scrollTop > 0) {
				$('html, body').animate({ scrollTop: 0 }, Math.min(Math.max(0, scrollTop * 10), 1500));
			}
		},


		calcShouldShowConsent() {
			if(window.platform !== 'web') {
				this.showCookieConsent = false;
				return;
			}
			this.showCookieConsent = !window.consentedStorage.getItem('consentMode');
			if(["privacy-statement", "privacy-statement-hu"].includes(this.$route.name)) {
				this.showCookieConsent = false;
			}else if (window.location.href.includes("privacy-statement")) {
				this.showCookieConsent = false;
			}
		},
		feedCurrentRouteId(routeIdUuid) {
			window.consentedStorage.setItem('currentRouteIdWithUuid', routeIdUuid);
		},


		/**
		 * @param {UserEventType} type
		 * @param {Record<string, string>} [data = {}]
		 */
		newEvent(type, data = {}) {
			// eventqueue({
			// 	type,
			// 	data,
			// 	time: new Date().toISOString()
			// });
			// eventqueue.flushQueueCache()
		},

		feedFiles(filesRaw) {
			window.consentedStorage.setItem('files', filesRaw);
		},

		onUnload() {
			if(window.checkedOut) return;
			this.newEvent('CHECK_OUT')
			window.checkedOut = true;
		},
		getDomPath(el) {
			var stack = [];
			while ( el.parentNode != null ) {
				var sibCount = 0;
				var sibIndex = 0;
				for ( var i = 0; i < el.parentNode.childNodes.length; i++ ) {
					var sib = el.parentNode.childNodes[i];
					if ( sib.nodeName == el.nodeName ) {
						if ( sib === el ) {
							sibIndex = sibCount;
						}
						sibCount++;
					}
				}

				let suffix = "";

				let classes = ["unknown"];

				if(typeof el.className === 'string') {
					classes = el.className.split(" ");
				}

				if(el.hasAttribute('id') && el.id != '') {
					suffix = '#' + el.id;
				} else if (classes.length > 0) {
					suffix = '.' + classes.join(".");
					if(sibCount > 1) {
						suffix += ':eq(' + sibIndex + ')';
					}
				}else if (sibCount > 1) {
					suffix = ':eq(' + sibIndex + ')';
				}

				stack.unshift(el.nodeName.toLowerCase() + suffix);
				el = el.parentNode;
			}
			return stack.slice(1); // removes the html element
		},
		allFrontPageAdClosed() {
			this.visibleAd = false;
			localStorage.setItem('frontPageAdClosed', new Date().toISOString());
		}
	},
	watch: {
		$route(to, from) {
			this.networkError = false;
			this.clearMessages();
			this.onRouteChangeStuff(to);

			this.enabledRightMenu = RIGHT_MENU_ENABLED_IN.includes(this.$route.name);
			this.showRightMenu = false;
			this.staticRightMenu = false;
			Vue.set(Vue.prototype, '$rightMenu', []);
			this.calcShouldShowConsent();

			// if (to.name == 'route-planning-element') {
			//   setTimeout(() => {
			//     this.toggleRightMenu(true, true);
			//   }, 100);
			// }
		}
	},
	async created() {

		// Need to clear local storage because the new version of the app would not work with the old local storage
		const appVersion = versionConfig.appVersion;
		const currentWebAppVersion = Number(window.localStorage.getItem("bundleVersion") || "0")
		if (currentWebAppVersion < appVersion) {
			window.localStorage.clear();
			window.localStorage.setItem("bundleVersion", String(appVersion));
			window.location.reload()
		}

		window.sessionId = v4();

		this.$axios.interceptors.request.use(
			(config) => {
				if (!config.keepMessages && !config.background) {
					this.clearMessages();
				}

				let loggedInEmail = this.$store.getters.loggedInEmail;
				let loggedInUserToken = this.$store.getters.loggedInUserToken;
				if (loggedInEmail && loggedInUserToken) {
					config['auth'] = {
						username: loggedInEmail,
						password: loggedInUserToken
					};
				}

				config.headers['X-Lang'] = this.$i18n.locale;



				// this.showAjaxOverlay = true;
				// if (this.$route && this.$route.name == 'flying-map' && this.$store.getters.getNoInternet) {
				// 	config.baseURL = 'drpaisbgmjocfqextnvulkhflightserverbadurlwyqivcopdnwjkyhalrbugfxmtse://';
				// 	config.url = '-';
				// }

				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		this.loggedInUserData = this.$store.getters.getLoggedInUserData;
		const token = this.$store.getters.loggedInUserToken;
		if (token && window.platform !== 'web') {
			window.loadAppLink('r2gflightserver://refetch-files?token=' + token);
		}

		this.$axios.interceptors.response.use(
			(response, req) => {
				if(response.config.background) {
					return response;
				}
				this.showAjaxOverlay = false;
				if (response && response.data) {
					if (response.data.message) {// Should not happen
						setTimeout(() => {
							this.addMessage(response.data.message)
						}, 0);
					}
					if (response.data.messages) {
						setTimeout(() => {
							this.addMessages(response.data.messages)
						}, 0);
					}
				}
				return response;
			},
			(error) => {
				this.showAjaxOverlay = false;

				if (!error.config.keepMessages && !error.config.background && !error.response) {
					if (this.networkError === false) {
						//alert(this.$t('server.down'))
					}
					this.networkError = true
				}

				if (error.response && error.response.status === 401) {
					this.$store.dispatch('storeLoggedInUserToken', null)
					this.$store.dispatch('storeLoggedInEmail', null)
					this.setLoggedInUserData(null)
					if (this.$router.currentRoute.name !== 'login' && this.$router.currentRoute.meta && this.$router.currentRoute.loginRequired === true) {
						this.$router.replace('login');
					}
				}
				return Promise.reject(error);
			}
		);

		if (!this.loggedInUserData) {
			const userData = await this.$rest.getLoggedInUser();
			if(userData) {
				this.setLoggedInUserData(userData);
			}
			this.onRouteChangeStuff(this.$router.currentRoute);
		}

		const lang = this.$route.query.lang || window.consentedStorage.getItem('Language');
		if (lang && this.$i18n.messages[lang]) {
			if (this.$i18n.locale !== lang) {
				this.$i18n.locale = lang;
			}
		} else {
			let browserLang = navigator.language || navigator.userLanguage;
			if (browserLang && browserLang.toLowerCase().indexOf('hu') == 0) {
				browserLang = 'hu';
			} else {
				browserLang = 'en';
				// browserLang = 'hu';
			}
			this.$i18n.locale = browserLang;
		}

		window.addEventListener('beforeunload', this.onUnload);

		this.checkInTimeout = setTimeout(() => {
			window.checkedIn = true;
		}, 1000 * 2);

		await this.handleFrontPageAd();

	},
	async mounted() {
		if (this.$route.name === 'route-planning-element') {
			this.toggleRightMenu(true, true);
		} else {
			this.toggleRightMenu(false, false);
		}
		this.fullscreen = this.$route.meta.fullscreen === true;
		window.googleLogin = this.googleLogin;
		window.fbLoginNative = this.fbLoginNative;
		window.appleLogin = this.appleLogin;
		window.feedLocation = this.feedLocation;
		window.feedGpsPermission = this.feedGpsPermission;
		window.feedStartLocation = this.feedStartLocation;
		window.feedFcmToken = this.feedFcmToken;
		window.feedDevice = this.feedDevice;
		window.mikrotik = this.$mikrotik;
		window.feedCurrentRouteId = this.feedCurrentRouteId;
		window.newEvent = this.newEvent;
		window.onUnload = this.onUnload;
		window.feedFiles = this.feedFiles;

		this.calcShouldShowConsent();

		this.mapObjects = await this.$rest.getAllObjects();


		if(this.$route.meta.onlyGuest && !this.$route.query.oauth_id) {
			const userToken = this.$store.getters.loggedInUserToken;
			if(userToken) {
				await this.$router.replace('map');
			}
		}


		this.startElapsedTimer();
		if(this.$route.name === 'index') {
			if(!window.platform === 'web' && this.$store.state.loggedInUserToken) {
				await this.$router.replace('start-flight');
			}
		}else{
			if (this.$route.meta.redirectTo) {
				await this.$router.replace(this.$route.meta.redirectTo);
			}
		}


		window.addEventListener("click", (e) => {
			this.newEvent('CLICK', {
				route: this.$route.name,
				routePath: this.$route.path,
				x: e.clientX,
				y: e.clientY,
				width: window.innerWidth,
				height: window.innerHeight,
				target: e.target.tagName,
				id: e.target.id,
				class: e.target.className,
				innerText: e.target.innerText,
				path: this.getDomPath(e.target).join(' > '),
			})
		})


		window.addEventListener("error", (e) => {
			this.newEvent('ERROR', {
				route: this.$route.name,
				routePath: this.$route.path,
				message: e.message,
				url: e.filename,
				line: e.lineno,
				col: e.colno,
				stack: e.error ? e.error.stack : null,
			})
		})
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.onUnload);
	},
	destroyed() {
		window.removeEventListener("resize", this.onWindowResize); // Development purpose only
	},
	components: {
		Button, Dialog,
		FrontPageAd,
		ConstentPopup,
		AppStoreBadges,
		AjaxOverlay,
		MainMenu,
		Messages,
		HelpButton,
		Logo
	}
}
</script>

<style>

:root {
	--doch: 100vh;
}

html {
	touch-action: manipulation;
	height: 100%;
}

body{
	height: 100%;
	overflow: hidden;
}

html #app {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;
}

#app,
.btn {
	font-size: 15px;
}

html .site-header,
html .site-nav,
html .site-footer {
	flex: 0 0 auto;
}

.site-nav.bg-dark {
	background-color: #0086FF !important;
}

html .site-content {
	flex: 1;
}

body {
	overflow-x: hidden;
	overscroll-behavior-y: none;
}

main {
	background-color: #ffffff;
	padding: 15px;
	position: relative;
}

main.fullscreen {
	padding: 0;
	max-width: 100%;
}

.rightSideMenu {
	position: fixed;
	right: 0px;
	top: 0px;
	bottom: 0;
	width: 100%;
	max-height: 100vh;
	max-width: 245px;
	transform: translateX(235px);
	border-radius: 0;
	background-color: #eeeeee;
	z-index: 999;
	overflow-y: auto;
	-webkit-transition: transform .5s ease-in-out, box-shadow .5s ease-in-out;
	-moz-transition: transform .5s ease-in-out, box-shadow .5s ease-in-out;
	-o-transition: transform .5s ease-in-out, box-shadow .5s ease-in-out;
	transition: transform .5s ease-in-out, box-shadow .5s ease-in-out;
	display: none;
}

.rightSideMenuOpened {
	transform: translateX(0);
	box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
}

.rightMenuOpened .rightSideMenu {
	display: block !important;
}

.sideMenuArea {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	max-width: 235px;
	border-radius: 0;
	background-color: #eeeeee;
}

.mainArea {
	z-index: 1;
	position: relative;
	-webkit-transition: transform .5s ease-in-out, width .5s ease-in-out;
	-moz-transition: transform .5s ease-in-out, width .5s ease-in-out;
	-o-transition: transform .5s ease-in-out, width .5s ease-in-out;
	transition: transform .5s ease-in-out, width .5s ease-in-out;
	max-height: var(--doch);
	height: 100%;
}



.mainAreaOpened {
	-webkit-transform: translateX(235px);;
	transform: translateX(235px);
	box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
}

div:not(.menuOpened) .mainAreaClosedRight {
	width: 100%;
}

div:not(.menuOpened) .mainAreaOpenedRight {
	box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
	width: calc(100% - 235px);
}

.mainAreaOverlay {
	transition: background 0.5s;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	pointer-events: none;
	display: none;
}

.mainAreaOverlayOpened {
	background-color: rgba(255, 255, 255, 0.75);
	z-index: 2000;
}

.menuOpened .mainAreaOverlay,
.mainAreaOpenedRight .mainAreaOverlay {
	display: block;
	pointer-events: all;
}

@media (max-width: 1199px) {
	.mainArea main.container {
		max-width: 100% !important;
	}

	.sideMenuArea {
		display: none;
	}

	.menuOpened .sideMenuArea {
		display: block;
	}
}

@media (max-width: 600px) {
	div:not(.menuOpened) .mainAreaClosedRight {
		width: 100% !important;
		transform: translateX(0px);
	}

	div:not(.menuOpened) .mainAreaOpenedRight {
		box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
		width: 100% !important;
		transform: translateX(-235px);
	}
}


@media (min-width: 1200px) {
	#app {
		display: flex;
		flex-direction: row !important;
	}

	.sideMenuArea {
		position: static;
	}

	.rightSideMenu {
		position: static;
	}

	.mainArea {
		transition: none;
		transform: none;
		box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
	}

	.navbar .navbar-toggler {
		display: none;
	}

	.mainAreaOverlay {
		display: none;
	}
}

.header-logo {
	width: 100px;
	transform: scale(1.5) translate(25px, -1px);
}

.navbar-brand-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 375px) {
	.navbar-brand {
		display: inline-flex;
		height: 100%;
		align-items: center;
	}

	.navbar-brand svg:not(.logo) {
		width: 1rem !important;
	}

	.navbar-brand span {
		font-size: 0.8em;
	}

	.logo {
		height: 25px !important;
	}
}

.site-nav.bg-dark.nav-online {
	background-color: #1BD91B !important;
}

.site-nav.bg-dark.nav-offline {
	background-color: #FF0000 !important;
}

.lang-flag {
	height: 35px;
	width: 35px;
}

.calendar-class {
	width: 200px !important;
	min-width: min-content !important;
}
</style>
