<template>
	<div v-if="visible" class="bar shadow">
		<div class="position-relative d-flex align-items-center">
			<font-awesome-icon icon="times" size="lg" class="close-btn" @click="visible=false"/>
			<font-awesome-icon icon="money-bill" size="lg" class="mr-2"/>
			<p class="mb-0 content-text">{{$t('donationBar.donateDescription')}}</p>
			<Button class="ml-2" @click="$router.push('donation')">
				{{$t('donationBar.donate')}}
			</Button>
		</div>
	</div>
</template>

<script>
import Button from 'primevue/button';

export default {
	name: "DonationAdBar",
	components: {
		Button
	},
	data() {
		return {
			visible: true
		}
	}
}
</script>

<style scoped>
.bar {
	width: fit-content;
	padding: 0.75rem;
	background-color: white;
	border: 1px solid black;
	border-top-left-radius: 0.75rem;
	border-top-right-radius: 0.75rem;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
	margin: auto;
}

.close-btn {
	position: absolute;
	top: -23px;
	right: 0;
	cursor: pointer;
	z-index: 999;
	background-color: white;
	border: 1px solid black;
	border-radius: 50%;
	width: 20px;
}

@media screen and (max-width: 400px) {
	.content-text {
		width: min-content;
	}
}
</style>
