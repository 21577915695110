<template>
	<div class="map-container d-flex">
		<div id="map"></div>

		<button :class="followGps ? 'btn-active' : 'btn-inactive'" class="map-btn follow-button" @click="toggleFollowGps()"  v-show="!showElevationChart  && (selectedMarker != null || alwaysShowRouteButtons || currentGpsMarker != null)">
			<font-awesome-icon icon="crosshairs" size="2x" />
		</button>

		<button class="map-btn direct-to-button" @click="openDirectToModal"  v-show="!showElevationChart  && currentGpsMarker">
			<svg viewBox="0 0 187 182"  xmlns="http://www.w3.org/2000/svg">
				<path d="M77.7312 146H40.7638V36.9091H78.4769C89.3079 36.9091 98.6119 39.093 106.389 43.4609C114.201 47.7933 120.203 54.0256 124.393 62.1577C128.583 70.2898 130.679 80.0199 130.679 91.348C130.679 102.712 128.566 112.477 124.34 120.645C120.15 128.812 114.095 135.08 106.176 139.448C98.2923 143.816 88.8107 146 77.7312 146ZM60.5259 128.901H76.7724C84.3718 128.901 90.7106 127.516 95.7887 124.746C100.867 121.941 104.684 117.768 107.241 112.229C109.798 106.653 111.076 99.6932 111.076 91.348C111.076 83.0028 109.798 76.0781 107.241 70.5739C104.684 65.0341 100.902 60.897 95.8952 58.1626C90.9237 55.3928 84.7447 54.0078 77.3583 54.0078H60.5259V128.901Z" />
				<rect y="81.438" width="156" height="20.124" rx="2" />
				<rect x="40.7773" y="86" width="19.7461" height="11" />
				<path d="M183.67 89.3627C184.805 90.1591 184.805 91.8409 183.67 92.6373L151.652 115.098C150.327 116.028 148.504 115.08 148.504 113.461L148.504 68.5393C148.504 66.9203 150.327 65.9722 151.652 66.902L183.67 89.3627Z" />
			</svg>
		</button>

		<button :class="showElevationChart ? 'btn-active' : 'btn-inactive'" class="map-btn follow-button"
						@click="toggleElevationChart()"
						v-if="(selectedMarker != null || alwaysShowRouteButtons) && currentCoordinates && currentCoordinates.length > 0 && !showElevationChart">
			<font-awesome-icon icon="chart-area" size="2x" />
		</button>

		<div v-if="!disableZoonButtons">

			<button class="map-btn zoom-in-button" @click="zoomIn()">
				<font-awesome-icon icon="plus" size="2x" />
			</button>

			<button class="map-btn zoom-out-button" @click="zoomOut()">
				<font-awesome-icon icon="minus" size="2x" />
			</button>

		</div>

		<div class="coordinate-dashboard" v-if="dashData && Object.keys(dashData).length > 0">
			<h6 class="text-center px-2 pt-2 font-weight-bold">
				{{$t('map.dashboard' + (this.showMaxDashData ? 'Max' : ''))}}
			</h6>
			<div class="coordinate-dashboard-row" v-if="dashData.telemetryGpsAlt !== undefined">
				<span>
					<font-awesome-icon icon="arrow-up" />
					{{$t('map.telemetryGpsAlt' + (this.showMaxDashData ? 'Max' : ''))}}
				</span>
				<span>
					<b>{{ dashData.telemetryGpsAlt | formatNumber }} m</b>
				</span>
			</div>
			<div class="coordinate-dashboard-row" v-if="dashData.telemetryClimbRate !== undefined">
				<span>
					<font-awesome-icon icon="arrow-up" class="rotate-45" />
					{{$t('map.telemetryClimbRate' + (this.showMaxDashData ? 'Max' : ''))}}
				</span>
				<span>
					<b>{{ dashData.telemetryClimbRate | formatNumber }} m/s</b>
				</span>
			</div>
			<div class="coordinate-dashboard-row" v-if="dashData.telemetryCoConc !== undefined">
				<span>
					<font-awesome-icon icon="exclamation-triangle" />
					{{$t('map.telemetryCoConc' + (this.showMaxDashData ? 'Max' : ''))}}
				</span>
				<span>
					<b>{{ dashData.telemetryCoConc | formatNumber}} ppm</b>
				</span>
			</div>
			<div class="coordinate-dashboard-row" v-if="dashData.telemetryPressure !== undefined">
				<span>
					<font-awesome-icon icon="compress-arrows-alt" />
					{{$t('map.telemetryPressure' + (this.showMaxDashData ? 'Max' : ''))}}
				</span>
				<span>
					<b>{{ dashData.telemetryPressure | formatNumber('#') }} hPa</b>
				</span>
			</div>
			<div class="coordinate-dashboard-row" v-if="dashData.telemetryTemp !== undefined">
				<span>
					<font-awesome-icon icon="thermometer-half" />
					{{$t('map.telemetryTemp' + (this.showMaxDashData ? 'Max' : ''))}}
				</span>
				<span>
					<b>{{ dashData.telemetryTemp | formatNumber('#') }} °C</b>
				</span>
			</div>
			<div class="coordinate-dashboard-row" v-if="dashData.telemetryTurnRate !== undefined">
				<span>
					<font-awesome-icon icon="directions" />
					{{$t('map.telemetryTurnRate' + (this.showMaxDashData ? 'Max' : ''))}}
				</span>
				<span>
					<b>{{ (dashData.telemetryTurnRate / 60) | formatNumber }} °/s</b>
				</span>
			</div>

			<div class="coordinate-dashboard-row" v-if="dashData.telemetryAccelMax !== undefined">
				<span>
					<font-awesome-icon icon="tachometer-alt" />
					{{$t('map.telemetryAccel' + (this.showMaxDashData ? 'Max' : ''))}}
				</span>
				<span>
					<b>{{ dashData.telemetryAccelMax | formatNumber }} m/s<sup>2</sup></b>
				</span>
			</div>

		</div>


		<button class="btn-inactive map-btn export-button" @click="exportRoute()" v-show="alwaysShowRouteButtons && !showElevationChart && !isMobile">
			<font-awesome-icon icon="download" size="2x" />
		</button>


		<button :class="showExtraRoutes ? 'btn-active' : 'btn-inactive'" class="map-btn extra-routes-button"
			@click="toggleExtraRoutes()" v-show="canHideExtraRoutes && !showElevationChart">
			<font-awesome-icon icon="route" size="2x" />
		</button>

		<ElevationChart ref="elevationChart" @close="$e => showElevationChart = false" @dashData="onDashData" />

		<div class="top-container">
			<div class="my-flight-container" v-if="showCurrentGPS">
				<div>
					<font-awesome-icon icon="tachometer-alt" />
					{{ calculator.speedFromKmH(getCurrentGPS.speed) }}
				</div>

				<div>
					<font-awesome-icon icon="sort-amount-up" />
					{{ altitude }}
				</div>

				<div>
					<font-awesome-icon icon="compass" />
					{{ calculator.directionFromTrue(getCurrentGPS.course) }}
				</div>
			</div>
			<div class="direct-to-container" v-if="directToObject">
				<div>
					<b>ETA</b>
					{{ directToETA }}
				</div>

				<div>
					<font-awesome-icon icon="arrows-alt-h" />
					{{ directToDistance }}
				</div>

				<div>
					<font-awesome-icon icon="compass" />
					{{ directToAngle }}
				</div>
			</div>
			<div class="top-controls" v-if="directToObject">
				<MapScale ref="mapScale" :maxWidth="200" :map="map" />
				<div class="btn btn-primary mt-2" id="remove-navigation-btn" @click="removeDirectTo()" v-if="directToObject">
					<font-awesome-icon icon="ban" />
					{{ $t('map.remove-direct-to') }}
				</div>
			</div>
			<div class="top-controls top-controls-single" v-else>
				<MapScale ref="mapScale" :maxWidth="200" style="height: 20px" :map="map" />
			</div>
		</div>


		<div class="bottom-container" v-if="!showElevationChart">
			<div class="bottom-left-container">

			</div>
			<div class="bottom-right-container">
				<div class="airspaces-updated-at" v-if="lastAirSpaceUpdate != null && !currentCoordinates" @click="loadNewAirspaces()">
					<h6>{{ $t('flyingView.airspacesUpdated') }}:</h6>
					<span>{{ lastAirSpaceUpdate }}</span>
				</div>
        <div class="airspaces-updated-at text-right" v-if="route">
          <h6>{{ $t('flightView.registrationNumber') }}:</h6>
          <span>{{ route.registrationNumber }}</span>
          <h6>{{ $t('flightView.start') }}:</h6>
          <span>{{ route.startTime | formatDateTime }}</span>
        </div>
			</div>
		</div>

<!--		<DonationAdBar v-if="showDonationAd" />-->
	</div>
</template>
<script>

import ElevationChart from "@/components/map/ElevationChart";
import { Calculator, calcDistance } from "@/plugins/calculator";
import { FlightAreaManager } from "@/plugins/flightareamanager";
import { WebSocketManager } from "@/plugins/websocketmanager";
import { MenuManager } from "@/plugins/menumanager";
import { MapLayerManager } from "@/plugins/maplayermanager";
import { FlyingObjectManager } from "@/plugins/flyingobjectmanager";
import { TooltipManager } from "@/plugins/tooltipmanager";
import { IconCache } from "@/plugins/iconcache";
import { mapGetters } from "vuex";
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-polylinedecorator';
import 'leaflet-textpath';
import 'leaflet-rotate';
import 'leaflet.awesome-markers';
import Vue from "vue";
import axios from "axios";
import { AirportManager } from "@/plugins/airportmanager";
import { NavPointManager } from "@/plugins/navpointmanager";
import debounce from 'lodash/debounce';
import { throws } from "assert";
import {
	ROUTE_PLAN_CALCULATOR,
	TRACK_CEIL,
} from "@/plugins/utils";
import '@/plugins/L.Control.BetterScale.js';
import MapScale from '../map/MapScale.vue';
import { getPlaneIcon } from "@/plugins/planeUtils";
import elevationChart from "./ElevationChart.vue";
import AddPlaneModal from "@/components/plane/AddPlaneModal.vue";
import DirectToModal from "@/components/map/DirectToModal.vue";
import DonationAdBar from "@/components/donation/DonationAdBar.vue";


// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
	name: 'Map',
	components: {
		// DonationAdBar,
		ElevationChart,
		MapScale
	},
	props: {
		preferCanvas: {
			optional: true,
			type: Boolean,
			default: false
		},
		preferMapLayer: {
			type: String,
			default: null,
			optional: true
		},
		showDonationAd: {
			type: Boolean,
			optional: true,
			default: false,
		}
	},
	data() {
		return {
			selectedMarker: null,
			map: null,
			dashData: null,
			webSocketManager: null,
			flyingObjectManager: null,
			tooltipManager: null,
			followGps: false,
			rotateMap: false,
			showAirports: true,
			routePolylines: {},
			showElevationChart: false,
			disableZoonButtons: false,
			showExtraRoutes: true,

      /** @type {Coordinate[]} */
			currentCoordinates: null,
			actualPositionCoordinates: null,
			alwaysShowRouteButtons: false,
			currentGpsMarker: null,
			showCurrentGPS: false,
			registrationNumber: null,
			beaconType: null,
			planPolyLines: [],
			planDecorator: [],
			directToPolyLines: [],
			directToDecorator: [],
			planMarkers: [],
			alternatePolyLines: [],
			alternateMarker: [],
			alternateDecorator: [],
			calculator: null,
			flightAreaManager: null,
			airportManager: null,
			navpointManager: null,
			menuManager: null,
			mapLayerManager: null,
			iconCache: null,
			mapBearing: 0,
			minZoom: 7,
			directToObject: null,
			maxZoom: 19,
			draggingMarker: false,
			canFollowTraffic: false,
			canHideExtraRoutes: false,
			zoomLevel: 8,
			airspaceRefreshInterval: null,
			iframeMode: false,
      /** @type {Route} */
      route: null,
      routeData: {
        maxG: 0,
        minG: 0,
        maxSpeed: 0,
        minSpeed: 0,
        maxAltitude: 0,
        minAltitude: 0,
        distance: 0,
      },
			maxDashData: null,
			showMaxDashData: false,
		};
	},
	async mounted() {
		this.mapLayerManager = new MapLayerManager(this, this.preferMapLayer);
		this.loadSettings();
		window.feedRoute = this.feedRoute;
		window.feedNoInternetFlag = this.feedNoInternetFlag;


		if (window.inIframe) {
			const url = (window.location != window.parent.location)
				? document.referrer
				: document.location.href;

			if (url.includes('netbriefing')) {
				window.gtag('event', 'netbriefing_iframe', {})
				console.log('netbriefing_iframe', window.gtag);
				this.iframeMode = true;
			}
		}

		if (!this.map) {
			this.map = window.L.map('map', {
				rotate: true,
				touchRotate: false,
				rotateControl: false,
				zoomControl: false,
				minZoom: this.minZoom,
				maxZoom: this.maxZoom,
				preferCanvas: this.preferCanvas,
				reuseTiles: true,
				unloadInvisibleTiles: true,
				boxZoom: false,
				doubleClickZoom: false,
				scrollWheelZoom: 'center',
			});

			this.map.whenReady(() => {
				console.log('map ready');
				this.$emit('loaded', this);
			})

			this.map.on('viewreset', () => {
				console.log("viewreset");
				if(!this.getCurrentGPS || !this.followGps) return;
				this.map.panTo([this.getCurrentGPS.lat, this.getCurrentGPS.lng]);

				if(this.rotateMap) {
					this.map.setBearing(this.getCurrentGPS.course ? -this.getCurrentGPS.course : 0);
				}else {
					this.map.setBearing(0);
				}
			});

			this.map.on('dragend', () => {
				this.followGps = false;
			});

			this.map.attributionControl.setPrefix('Leaflet');
			this.map.attributionControl.addAttribution('OpenFlightMaps');
			this.map.attributionControl.addAttribution('OpenStreetMaps');

			this.canFollowTraffic = this.$route.name.indexOf('flying-map') < 0;
			this.canHideExtraRoutes = this.$route.name.indexOf('flight-observer') >= 0;

			let mapView = this.$route && (this.$route.name === 'map' || this.$route.name === 'index' || this.$route.name === 'flight-observer');

			this.iconCache = new IconCache();
			this.calculator = new Calculator(this);
			this.flightAreaManager = new FlightAreaManager(this.map, this, this.iframeMode || this.$route.name === "map" || this.$route.name === "index" || (this.$route.name === "flying-map" && this.isMobile), (this.$route.name === "flying-map" && this.isMobile) ? 'rgba(220,20,60,0.4)' : "#dc143c");
			this.airportManager = new AirportManager(this.map, this.$rest, this);
			this.navpointManager = new NavPointManager(this.map, this.$rest, this);
			this.webSocketManager = new WebSocketManager(this);
			this.flyingObjectManager = new FlyingObjectManager(this);
			this.tooltipManager = new TooltipManager(this, mapView);
			this.menuManager = new MenuManager(this);

			this.mapLayerManager.swapLayers(false);


			const x = Number(this.$route.query.x || '0') || (window.inIframe ? 47.4 : 47.2);
			const y = Number(this.$route.query.y || '0') || 19.0;
			const zoom = Number(this.$route.query.zoom || '0') || (window.inIframe ? 10 : this.zoomLevel);

			this.map.setView([x, y], zoom);

			this.map.on('click', (e) => {
				if (!this.draggingMarker) {
					this.mapClickListen(e);
				}
			});

			this.debouncedUpdateIcons = debounce(this.updateIcons, 500);

			this.map.on('zoomend', (e) => {
				this.debouncedUpdateIcons(e);
				this.zoomLevel = e.target.getZoom();
			});


			this.map.on('contextmenu', (e) => {
				let l = e.latlng;
				console.log(l.lat.toFixed(8) + ',' + l.lng.toFixed(8));
				return false;
			});

			setTimeout(() => this.map.invalidateSize(), 500);

			this.airportManager.loadAirports().then(() => {
				this.airportManager.loadSettings();
			});
			this.navpointManager.loadNavPoints().then(() => {
				this.navpointManager.loadSettings();
			});

			this.loadNewAirspaces();
			this.airspaceRefreshInterval = setInterval(() => this.loadNewAirspaces(), 1000 * 60 * 5); // refresh every 5 minutes

			if (this.$route && (this.$route.name.indexOf('map') >= 0 || this.$route.name.indexOf('flight-observer') >= 0)) {
				this.menuManager.loadMainMenu();

				window.loadAppLink('r2gflightserver://entering-map');
			}

			this.$refs.mapScale.init(this.map)
		}
	},
	beforeDestroy() {
		this.webSocketManager.closeWebSocket();
		window.feedRoute = null;
		window.feedNoInternetFlag = null;
		this.flyingObjectManager.destroy();

		if (this.airspaceRefreshInterval) {
			clearInterval(this.airspaceRefreshInterval);
		}

		window.loadAppLink('r2gflightserver://leaving-map');
	},
	methods: {
		onDashData(coordinate) {
			if(this.$route.name !== 'flight-observer') return;
			this.showMaxDashData = !coordinate;
			if(!coordinate) {
				this.dashData = this.maxDashData;
				return;
			}
			this.dashData = {};


			const params = [
				'telemetryClimbRate',
				'telemetryGpsAlt',
				'telemetryCoConc',
				'telemetryPressure',
				'telemetryTemp',
				'telemetryTurnRate',
				'telemetryAccelMin',
				'telemetryAccelMax',
			];

			for (const param of params) {

				if (coordinate[param] === undefined) {
					continue;
				}
				if(coordinate[param] === null) {
					continue;
				}

				this.dashData[param] = coordinate[param];
			}

			this.$refs.mapScale.update();
		},
		padNumber(num) {
			return num.toString().padStart(2, "0");
		},
		setDirectToObject(object) {
			this.directToObject = object;
		},

    setRoute(route) {
      this.route = route;
      this.routeData = {
        maxG: this.currentCoordinates.reduce((acc, cur) => Math.max(acc, cur.telemetryAccelMax), 0),
        minG: this.currentCoordinates.reduce((acc, cur) => Math.min(acc, cur.telemetryAccelMin), 0),
        maxSpeed: this.currentCoordinates.reduce((acc, cur) => Math.max(acc, cur.speed), 0),
        minSpeed: this.currentCoordinates.reduce((acc, cur) => Math.min(acc, cur.speed), 0),
        maxAltitude: this.currentCoordinates.reduce((acc, cur) => Math.max(acc, cur.altitude), 0),
        minAltitude: this.currentCoordinates.reduce((acc, cur) => Math.min(acc, cur.altitude), 0),
        distance: this.currentCoordinates.reduce((acc, cur, index) => {
          if (index === 0) return acc;
          const prev = this.currentCoordinates[index - 1];
          return acc + calcDistance(cur.latitude, cur.longitude, prev.latitude, prev.longitude);
        }, 0),
      }
    },
		updateIcons() {
			this.flyingObjectManager.updateIcons();
			this.navpointManager.updateMarkers();
			this.airportManager.updateMarkers();
		},
		getCurrentCoordinate() {
			return this.actualPositionCoordinates;
		},
		clearPlanElements() {
			this.planMarkers.forEach(marker => {
				marker.remove(this.map);
			});
			this.planMarkers = [];
			this.planPolyLines.forEach(polyLine => {
				polyLine.remove(this.map);
			});
			this.planPolyLines = [];
			this.planDecorator.forEach(decorator => {
				decorator.remove(this.map);
			});
			this.planDecorator = [];

		},
		setupBriefing() {
			this.clearPlanElements();
			this.flightAreaManager.clear();
			// this.mapLayerManager.loadLayer('base')
			this.map._handlers.forEach(function (handler) {
				handler.disable();
			});
			this.disableZoonButtons = true;
		},
		clearAlternatesElements() {
			this.alternateMarker.forEach(marker => {
				marker.remove(this.map);
			});
			this.alternateMarker = [];
			this.alternatePolyLines.forEach(polyLine => {
				polyLine.remove(this.map);
			});
			this.alternatePolyLines = [];
			this.alternateDecorator.forEach(decorator => {
				decorator.remove(this.map);
			});
			this.alternateDecorator = [];

		},
		drawLine(coordinates, color = '#00ffe1', text = null, isAlternate = false, textColor = "red") {
			let polyline = window.L.polyline(coordinates, {
				color: color,
				weight: 5,
			});
			if (text) {
				const ss = (parseInt(text) > 180 && parseInt(text) < 360 ? 180 : 0);
				text += '°';
				polyline.setText(text, {
					repeat: false,
					orientation: ss ? 'flip' : '',
					attributes: {
						fill: textColor,
						'font-weight': 'bold',
						'font-size': '26',
						'stroke-width': 1,
						stroke: "white",
						dx: 40,
						dy: 5
					}
				});
			}
			polyline.addTo(this.map)
			let decorator = window.L.polylineDecorator(polyline, {
				patterns: [
					{
						offset: '50%',
						repeat: 0,
						symbol: window.L.Symbol.arrowHead({ pixelSize: 15, polygon: false, pathOptions: { stroke: true } })
					}
				]
			}).addTo(this.map);
			if (isAlternate) {
				this.alternatePolyLines.push(polyline);
				this.alternateDecorator.push(decorator);
			} else {
				this.planPolyLines.push(polyline);
				this.planDecorator.push(decorator);
			}
		},
		clearDirectToPolyLines() {
			this.directToPolyLines.forEach(polyline => {
				polyline.remove(this.map);
			});
			this.directToPolyLines = [];
			this.directToDecorator.forEach(decorator => {
				decorator.remove(this.map);
			})
			this.directToDecorator = [];
		},
		drawLineFromCurrentGpsTo(coordinates, text = "") {
			if (this.getCurrentGPS) {
				let polyline = window.L.polyline([[this.getCurrentGPS.lat, this.getCurrentGPS.lng], coordinates], {
					color: '#e90383',
					weight: 5,
				});
				const ss = (parseInt(text) > 180 && parseInt(text) < 360 ? 180 : 0);
				text += '°';
				polyline.setText(text, {
					repeat: false,
					orientation: ss ? 'flip' : '',
					attributes: {
						fill: 'red',
						'font-weight': 'bold',
						'font-size': '26',
						'stroke-width': 1,
						stroke: "white",
						dx: 40,
						dy: 5
					}
				});
				polyline.addTo(this.map)
				let decorator = window.L.polylineDecorator(polyline, {
					patterns: [
						{
							offset: '50%',
							repeat: 0,
							symbol: window.L.Symbol.arrowHead({ pixelSize: 15, polygon: false, pathOptions: { stroke: true } })
						}
					]
				}).addTo(this.map)
				this.directToPolyLines.push(polyline);
				this.directToDecorator.push(decorator);
			} else {
				alert(this.$t('map.no-active-gps-coordinate'))
			}
		},
		mapClickListen(e) {
			this.$emit("mapClick", e.latlng);
		},
		drawMarker(coordinates, isFirst, options = {}, isAlternate = false) {
			options.rotateWithView = true;
			let marker = window.L.marker(coordinates, options);
			if (options.draggable) {
				marker.on('dragstart', (e) => {
					this.draggingMarker = true;
				});
				marker.on('dragend', (e) => {
					this.draggingMarker = false;
					this.$emit('markerDragEnd', e, coordinates);
				});
			}
			marker.addTo(this.map);
			if (isFirst) {
				marker._icon.classList.add("huechange");
			}

			if (!isAlternate) {
				this.planMarkers.push(marker);
			}
		},
		setRoutePlanCenter(coordinate, zoom) {
			this.map.setView(coordinate, zoom);
		},
		async loadAirspaces() {
			await this.flightAreaManager.loadAirspaces('ofmx_lh.ofmx');
			this.flightAreaManager.loadSettings()
		},
		async loadNewAirspaces() {
			await this.flightAreaManager.loadNewAirspaces();
			this.flightAreaManager.loadSettings()
		},
		feedRoute(data) {
			if(typeof data === 'string') {
				data = JSON.parse(data);
			}
			let polyline = this.addRoute(data);
			this.currentGpsMarker.polyline = polyline;
			this.currentGpsMarker.routeId = data.routeId;

			if (this.showElevationChart) {
				this.$refs.elevationChart.refresh(data.coordinates, true);
			}
		},
		refreshElevationChart() {
			if (this.showElevationChart) {
				this.$refs.elevationChart.refreshSimple();
			}
		},
		feedNoInternetFlag(noInternet) {
			this.$store.dispatch('storeNoInternet', noInternet);
		},
		addRoute(data) {
			if(!data) return null;
			let polyline = window.L.multiPolyline(data.coordinates, {
				color: '#ff0000',
				weight: 3,
				map: this.map
			});

			this.routePolylines[data.routeId] = polyline;

			this.currentCoordinates = data.coordinates;


			if(this.$route.name === "flight-observer") {
				this.maxDashData = {
					telemetryAccelMin: Math.max(...this.currentCoordinates.map(x => x.telemetryAccelMin)) || undefined,
					telemetryAccelMax: Math.max(...this.currentCoordinates.map(x => x.telemetryAccelMax)) || undefined,
					telemetryClimbRate: Math.max(...this.currentCoordinates.map(x => x.telemetryClimbRate)) || undefined,
					telemetryGpsAlt: Math.max(...this.currentCoordinates.map(x => x.telemetryGpsAlt)) || undefined,
					telemetryPressure: Math.min(...this.currentCoordinates.map(x => x.telemetryPressure)) || undefined,
					telemetryTemp: Math.max(...this.currentCoordinates.map(x => x.telemetryTemp)) || undefined,
				}
				let d = {}
				for (let key in this.maxDashData) {
					if(!this.maxDashData[key]) continue;
					d[key] = this.maxDashData[key];
				}

				this.maxDashData = d;

				if(Object.values(this.maxDashData).length === 0) {
					this.maxDashData = null;
				}

				this.dashData = this.maxDashData;
				this.showMaxDashData = true;
			}

			return polyline;
		},
		removeRoute(routeId) {
			if (routeId) {
				let polyline = this.routePolylines[routeId];
				if (polyline) {
					polyline.remove();
					delete this.routePolylines[routeId];
				}
			}
		},
		moveToRoute(routeId) {
			let polyline = this.routePolylines[routeId];
			if (polyline) {
				this.map.fitBounds(polyline.getBounds());
			}
		},
		moveTo(latitude, longitude, zoom) {
			this.zoomLevel = zoom;
			this.map.setView([latitude, longitude], this.zoomLevel);
		},
		async openWebSocket(url, onOpen) {
			return await this.webSocketManager.openWebSocket(url, onOpen);
		},
		updateRouteWithNewCoordinates(routeId, coordinates) {
			let polyline = this.routePolylines[routeId];

			if (!polyline) return;

			for (let coordinate of coordinates) {
				polyline.addCoordinate(coordinate);
			}
			if (this.showElevationChart) {
				this.$refs.elevationChart.addCoordinates(coordinates);
			}

			this.currentCoordinates.push(...coordinates);
		},
		registerToRouteUpdates(routeId) {
			this.webSocketManager.registerToRouteUpdates(routeId);
		},
		unregisterFromRouteUpdates(routeId) {
			this.webSocketManager.unregisterFromRouteUpdates(routeId);
		},

		/**
		 * @param {string} [registrationNumber]
		 * @param {boolean} [onlyThis]
		 */
		registerToFlyingObjectUpdates(registrationNumber, onlyThis = false) {
			let transponderTypes = []
			for (let tType in this.flyingObjectManager.transponderTypes) {
				if (this.flyingObjectManager.transponderTypes[tType]) {
					transponderTypes.push(tType);
				}
			}

			if(this.flyingObjectManager.internalFilter) {
				transponderTypes.push('INTERNAL');
			}

			let tiles = JSON.parse(window.localStorage.getItem('tiles') || '["hu"]');
			if(window.platform === 'web') {
				tiles = window.allTiles || [];
			}


			this.webSocketManager.registerToFlyingObjectUpdates({
				registrationNumber: registrationNumber || this.registrationNumber,
				maxViewDistance: this.flyingObjectManager.maxViewRange,
				maxAltitude: this.flyingObjectManager.maxElevFilter,
				transponderTypes: transponderTypes.join(','),
				onlyThis: onlyThis,
				countries: tiles.map(x => x.toUpperCase()).join(','),
			});
		},
		unregisterFromFlyingObjectUpdates() {
			this.webSocketManager.unregisterFromFlyingObjectUpdates();
		},
		registerToDemoGPS(registrationNumber) {
			this.webSocketManager.registerToDemoGPS(registrationNumber);
		},
		setShowCurrentGPS(showCurrentGPS, registrationNumber, beaconType) {
			if (window.consentedStorage.getItem('maxZoom') == null) {
				this.maxZoom = 12;
			}
			if (window.consentedStorage.getItem('mapLayer') == null) {
				this.mapLayerManager.loadLayer('merged');
			}

			if (this.showCurrentGPS && !showCurrentGPS) {
				if (this.currentGpsMarker) {
					this.currentGpsMarker.remove();
					this.currentGpsMarker = null;
				}
			} else if (!this.showCurrentGPS && showCurrentGPS) {
				this.followGps = true;
				this.registrationNumber = registrationNumber;
			}
			this.beaconType = beaconType;
			this.showCurrentGPS = showCurrentGPS;
		},
		toggleFollowGps() {
			if (this.followGps) {
				this.followGps = false;
			} else {
				this.followGps = true;
				if (this.selectedMarker) {
					if (this.canFollowTraffic) {
						this.map.panTo(this.selectedMarker.getLatLng(), {
							noMoveStart: true
						});
					}
				}
			}
		},
		openDirectToModal() {
			this.$modal.show(DirectToModal, {}, {
				height: 'auto',
				clickToClose: true,
				adaptive: true,
			}, {
				'before-close': ({ params }) => {
					if(!params) return;
					console.log(params);

					const selectedObject = {
						...params,
						latLng: {
							lat: params.latitude,
							lng: params.longitude,
						}
					}

					this.$emit('directTo', selectedObject);
				}
			});
		},
		async exportRoute() {
			const routeId = this.$route.params.id
			if(!routeId) return;

			const blob = await this.$rest.exportRoute(routeId);
			if(!blob) return;
			const fileURL = window.URL.createObjectURL(blob);
			const fileLink = document.createElement('a');
			fileLink.href = fileURL;
			fileLink.setAttribute('download', `${routeId}.gpx`);
			document.body.appendChild(fileLink);

			fileLink.click();

			setTimeout(() => {
				document.body.removeChild(fileLink);
			}, 1000);
		},
		zoomIn() {
			let z = this.map.getZoom();
			if (z < this.maxZoom) {
				this.map.setZoom(z + 1);
			} else {
				this.map.setZoom(this.maxZoom);
			}
		},
		zoomOut() {
			let z = this.map.getZoom();
			if (z > this.minZoom) {
				this.map.setZoom(z - 1);
			} else {
				this.map.setZoom(this.minZoom);
			}
		},
		toggleRotateMap(on, save) {
			this.rotateMap = on;
			if (!on) {
				this.map.setBearing(0);
			}
			if (save) {
				window.consentedStorage.setItem('rotateMap', on ? "1" : "0");
			}
		},
		toggleElevationChart() {
			if (this.showElevationChart) {
				this.$refs.elevationChart.hide();
				this.showElevationChart = false;
			} else if (this.currentCoordinates && this.currentCoordinates.length > 0) {
				this.$refs.elevationChart.show(this.currentCoordinates, this.map);
				this.showElevationChart = true;
			}
		},
		toggleExtraRoutes() {
			let ll = [
				this.planPolyLines, this.planDecorator, this.planMarkers,
				this.directToPolyLines, this.directToDecorator,
				this.alternatePolyLines, this.alternateDecorator, this.alternateMarker
			];

			if (this.showExtraRoutes) {
				this.showExtraRoutes = false;

				for (let l of ll) {
					for (let o of l) {
						o.remove();
					}
				}
			} else {
				this.showExtraRoutes = true;

				for (let l of ll) {
					for (let o of l) {
						o.addTo(this.map);
					}
				}
			}
		},
		loadSettings() {
			let v = window.consentedStorage.getItem('rotateMap');
			if (v === '1') {
				this.toggleRotateMap(true, false);
			}

			v = window.consentedStorage.getItem('minZoom');
			if (v) {
				this.minZoom = Number(v);
			}

			v = window.consentedStorage.getItem('maxZoom');
			if (v) {
				this.maxZoom = Number(v);
			}

			this.mapLayerManager.loadSettings();
		},
		getCurrentGpsIcon() {
			if ((this.registrationNumber || '').includes("UFO")) {
				this.beaconType = "UFO";
			}

			return getPlaneIcon(this.beaconType, true);
		},
		calcDistanceToDirectToObject() {
			if (!this.directToObject) return null;

			const lat1 = (this.getCurrentGPS || {}).lat;
			const lng1 = (this.getCurrentGPS || {}).lng;
			if (!lat1 || !lng1) return null;

			const lat2 = (this.directToObject.latLng || {}).lat;
			const lng2 = (this.directToObject.latLng || {}).lng;
			if (!lat2 || !lng2) return null;

			return calcDistance(lat1, lng1, lat2, lng2);
		},
		removeDirectTo() {
			this.clearDirectToPolyLines();
			this.directToObject = null;
		},
	},
	computed: {
		...mapGetters([
			'getCurrentGPS'
		]),
		isMobile() {
			return window.platform === 'ios' || window.platform === 'android';
		},
		directToDistance() {
			const distance = this.calcDistanceToDirectToObject();
			if (!distance) return null;
			return this.calculator.distanceFromKm(distance);
		},
		directToETA() {
			const distance = this.calcDistanceToDirectToObject();
			if (!distance) return null;

			const speed = (this.getCurrentGPS || {}).speed;
			if (!speed) return null;

			const ETA = distance / speed * 60;
			return `${Math.floor(ETA).toString().padStart(2, '0')}:${Math.round((ETA % 1) * 60).toString().padStart(2, '0')}`
		},
		directToAngle() {
			const lat1 = (this.getCurrentGPS || {}).lat;
			const lng1 = (this.getCurrentGPS || {}).lng;
			if (!lat1 || !lng1) return null;

			const lat2 = (this.directToObject.latLng || {}).lat;
			const lng2 = (this.directToObject.latLng || {}).lng;
			if (!lat2 || !lng2) return null;


			let trueTrack = ROUTE_PLAN_CALCULATOR.TRUE_TRACK(lat1, lng1, lat2, lng2)

			if (trueTrack < 0) {
				trueTrack = Math.ceil(360 + trueTrack);
			} else {
				trueTrack = Math.ceil(trueTrack);
			}


			return this.calculator.directionFromTrue(trueTrack);
		},
		altitude() {
			let alt = this.getCurrentGPS.alt;
			if (window.consentedStorage.getItem('altitudeOffset')) {
				let offset = parseFloat(window.consentedStorage.getItem("altitudeOffset"));
				if (!isNaN(offset)) {
					alt += offset;
				}
			}
			return this.calculator.elevationFromMeter(Math.max(alt, 0));
		},
		lastAirSpaceUpdate() {
			//If you update this, update the same in the FlyingView component as well
			if (this.showCurrentGPS) return null;

			const lastUpdateRaw = this.$store.getters.getAirspaceUpdatedAt;
			if (!lastUpdateRaw) {
				return null;
			}

			if (isNaN(parseInt(lastUpdateRaw))) {
				return null;
			}

			return window.formatDateTime(parseInt(lastUpdateRaw));
		},
	},
	watch: {
		canFollowTraffic() {
				if(!this.canFollowTraffic) {
					this.map.setBearing(0)
				}
		},
		getCurrentGPS(currentGPS) {
			if (!this.showCurrentGPS) {
				return;
			}

			// console.log(currentGPS.lat, currentGPS.lng, currentGPS.alt, currentGPS.course, currentGPS.speed, currentGPS.time);

			this.actualPositionCoordinates = { lat: currentGPS.lat, lng: currentGPS.lng };

			if (!this.currentGpsMarker) {
				this.currentGpsMarker = window.L.marker([currentGPS.lat, currentGPS.lng], {
					rotateWithView: true,
					icon: window.L.icon({
						iconUrl: this.getCurrentGpsIcon(),
						iconSize: [50, 50],
						iconAnchor: [25, 25]
					}),
					interactive: true
				});
				this.currentGpsMarker.addTo(this.map);

				this.currentGpsMarker.on('click', () => {
					if (this.selectedMarker) {
						this.flyingObjectManager.onClosePlane(this.selectedMarker, false);
					}

					this.$emit("clickedOnGpsMarker", this.currentGpsMarker);
				});

				this.$emit("clickedOnGpsMarker", this.currentGpsMarker);

				this.map.stop();
				this.map.panTo([currentGPS.lat, currentGPS.lng], {
					noMoveStart: true
				});
				this.map.setZoom(11);
			} else {
				this.currentGpsMarker.setLatLng([currentGPS.lat, currentGPS.lng]);
			}

			if (currentGPS.course && currentGPS.course != -1.0) {
				if (!(this.registrationNumber || '').includes("UFO")) this.currentGpsMarker.setRotation(currentGPS.course * (Math.PI / 180.0));
			}

			if (this.followGps && (!this.selectedMarker || !this.canFollowTraffic)) {
				this.map.stop();
				this.map.panTo([currentGPS.lat, currentGPS.lng], {
					noMoveStart: true,
				});
				if (this.rotateMap) {
					this.map.setBearing(currentGPS.course ? -currentGPS.course : 0);
				}
			}

			if (this.currentGpsMarker.polyline) {
				let c = {
					latitude: currentGPS.lat,
					longitude: currentGPS.lng,
					altitude: currentGPS.alt,
					speed: currentGPS.speed,
					course: currentGPS.course,
					noInternet: false,
					time: currentGPS.time
				};
				this.currentGpsMarker.polyline.addCoordinate(c);
			}
		},
		minZoom() {
			this.map.options.minZoom = this.minZoom;
			window.consentedStorage.setItem('minZoom', this.minZoom);
		},
		maxZoom() {
			this.map.options.maxZoom = this.maxZoom;
			window.consentedStorage.setItem('maxZoom', this.maxZoom)
		},
	},
	emits: ["mapClick", 'markerDragEnd', 'clickedOnGpsMarker', 'loaded', 'directTo'],
}
</script>

<style>
/* SAFARI LEAFLET CONTROLS FIX */
.leaflet-control-container .leaflet-top,
.leaflet-control-container .leaflet-bottom {
	will-change: transform;
}


.map-container {
	flex-grow: 1;
	position: relative;
}

#map {
	flex-grow: 1;
	z-index: 1;
}

.map-btn {
	width: 60px;
	height: 60px;
	position: absolute;
	z-index: 901;
	border-radius: 0.5rem;
	border: 3px solid #000000;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	color: #000000;
	transition: 0.1s scale ease-in-out;
}

.map-btn:active {
	scale: 1.03;
}

.follow-button {
	left: 10px;
	bottom: 10px;
}

.direct-to-button {
	left: 80px;
	bottom: 10px;
	transition: all 0.1s ease-in-out;
	box-shadow: none !important;
	padding: 0.4rem;
}

.direct-to-button svg {
	width: 100%;
	height: 100%;
}


.zoom-in-button {
	left: 10px;
	top: 10px;
}

.zoom-out-button {
	left: 10px;
	top: 80px;
}

.zoom-in1-button {
	left: 80px;
	top: 10px;
}

.zoom-out1-button {
	left: 80px;
	top: 80px;
}

.chart-button {
	position: relative;
	margin-top: 10px;
}

.export-button {
	left: 10px;
	bottom: 150px;
}

.extra-routes-button {
	left: 10px;
	bottom: 80px;
}

.btn-inactive {
	background-color: #0086FF;
	border-color: #ffffff;
	color: #ffffff;
}

.btn-active {
	background-color: #ffffff;
	border-color: #0086FF;
	color: #0086FF;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.75), inset 0 0 12px #58CD36, inset 0 0 6px #58CD36;
}

.leaflet-tooltip.map-tooltip {
	background-color: rgba(0, 0, 0, 0.7);
	border-color: #000000;
	z-index: 1;
	color: #ffffff;
	padding-top: 2px;
	padding-bottom: 2px;
}

.leaflet-tooltip.map-tooltip-me {
	background-color: rgba(57, 68, 188, 0.9);
}

.leaflet-tooltip-left.map-tooltip:before {
	border-left-color: #000000;
}

.leaflet-tooltip-right.map-tooltip:before {
	border-right-color: #000000;
}

.leaflet-tooltip-top.map-tooltip:before {
	border-top-color: #000000;
}

.leaflet-tooltip-bottom.map-tooltip:before {
	border-bottom-color: #000000;
}


.top-container {
	position: absolute;
	top: 10px;
	left: 80px;
	right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}


.my-flight-container {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	z-index: 899;
}

.my-flight-container>div {
	background: #0468c2;
	color: #FFFFFF;
	text-shadow: 0 0 4px #000000, 0 0 8px #000000;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
	margin-right: 6px;
	padding: 2px 5px;
	border-radius: 10px;
	width: 33.33333%;
	text-align: center;
	font-size: 0.9rem;
}

.my-flight-container>div:last-child {
	margin-right: 0;
}

.my-flight-container>div>svg {
	margin-right: 2px;
}

@media (max-width: 360px) {
	.my-flight-container>div>svg {
		display: none;
	}
}

.direct-to-container {
	position: relative;
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	width: 100%;
	z-index: 899;
}

.direct-to-container>div {
	background: #e90383;
	color: #FFFFFF;
	text-shadow: 0 0 4px #000000, 0 0 8px #000000;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
	margin-right: 6px;
	padding: 2px 5px;
	border-radius: 10px;
	width: 33.33333%;
	text-align: center;
	font-size: 0.9rem;
}

.direct-to-container>div:last-child {
	margin-right: 0;
}

.direct-to-container>div>svg {
	margin-right: 2px;
}

@media (max-width: 360px) {

	.direct-to-container>div>svg,
	.direct-to-container>div>b {
		display: none;
	}
}

.top-controls {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	justify-content: space-between;
}

.top-controls>* {
	z-index: 899;
}

.top-controls-single {
	justify-content: flex-end;
	flex-direction: column;
	align-items: flex-end;
}

@media (max-width: 576px) {
	.top-controls {
		flex-direction: column-reverse;
		align-items: flex-end;
		justify-content: unset;
	}
}


#remove-navigation-btn {
	background: #e90383 !important;
	font-size: 0.7rem;
}

@media (min-width: 520px) {
	#remove-navigation-btn {
		font-size: 0.8rem;
	}
}

@media (min-width: 650px) {
	#remove-navigation-btn {
		font-size: 1rem;
	}
}


.bottom-container {
	position: absolute;
	bottom: 10px;
	left: 10px;
	right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.bottom-container > * {
	z-index: 899;
}

.bottom-container .bottom-left-container {
	display: flex;
	flex-direction: column;
}

.bottom-container .bottom-right-container {
	display: flex;
	flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.airspaces-updated-at {
	background: rgba(0, 0, 0, 0.162);
	backdrop-filter: blur(5px);
	padding: 0.3rem;
	margin-bottom: 10px;
	border-radius: 5px;
}

.airspaces-updated-at h6 {
	margin: 0;
	font-size: 0.8em;
	text-align: right;
}

.airspaces-updated-at span {
	font-weight: 900;
	display: block;
	font-size: 0.9em;
}


img.huechange {
	filter: hue-rotate(120deg);
	z-index: 888 !important;
}

.coordinate-dashboard {
	position: absolute;
	top: 60px;
	right: 10px;
	display: flex;
	flex-direction: column;
	z-index: 899;
	background: white;
	border-radius: 5px;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
	padding: 0.3rem;
	border: solid 1px black;
}

.coordinate-dashboard-row{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
}

</style>
