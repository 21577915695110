<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="arrow-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1 class="text-center mb-8">{{$t('gtc.title')}}</h1>
		<p class="mb-2">{{$t('gtc.content1')}}</p>
		<p class="mb-1">{{$t('gtc.content2')}}</p>
		<ol>
			<li>{{$t('gtc.list1_1')}}</li>
			<li>{{$t('gtc.list1_2')}}</li>
		</ol>

		<h2 class="mt-5 mb-3">{{$t('gtc.title2')}}</h2>
		<p class="mb-2">{{$t('gtc.content3')}}</p>
		<p class="mb-2">{{$t('gtc.content4')}}</p>
		<p class="mb-2">{{$t('gtc.content5')}}</p>
		<p class="mb-2">{{$t('gtc.content6')}} <a href="https://simplepay.hu/vasarlo-aff">https://simplepay.hu/vasarlo-aff</a></p>
		<p class="font-bold">{{$t('gtc.content7')}}</p>

		<h2 class="mt-5 mb-3">{{$t('gtc.title3')}}</h2>
		<ol>
			<li>
				<span>{{$t('gtc.list2_1')}}</span>
				<ul>
					<li>{{$t('gtc.list2_1_1')}}</li>
					<li>{{$t('gtc.list2_1_2')}}</li>
					<li>{{$t('gtc.list2_1_3_1')}}<a href="mailto:support@pilotnet.hu">support@pilotnet.hu</a>{{$t('gtc.list2_1_3_2')}}</li>
					<li>{{$t('gtc.list2_1_4')}}</li>
				</ul>
			</li>
		</ol>

		<h2 class="mt-5 mb-3">{{$t('gtc.title4')}}</h2>
		<ol>
			<li>{{$t('gtc.list3_1')}}</li>
			<li>{{$t('gtc.list3_2')}}</li>
			<li>{{$t('gtc.list3_3')}}</li>
			<li>{{$t('gtc.list3_4')}}</li>
			<li>{{$t('gtc.list3_5')}}</li>
			<li>{{$t('gtc.list3_6')}}</li>
			<li>{{$t('gtc.list3_7')}}</li>
			<li>{{$t('gtc.list3_8')}}</li>
		</ol>

		<h2 class="mt-5 mb-3">{{$t('gtc.title5')}}</h2>
		<ol>
			<li>{{$t('gtc.list4_1')}}</li>
			<li>{{$t('gtc.list4_2')}}</li>
			<li>{{$t('gtc.list4_3')}}</li>
			<li>{{$t('gtc.list4_4')}}</li>
			<li>{{$t('gtc.list4_5')}}</li>
			<li>{{$t('gtc.list4_6')}}</li>
			<li>{{$t('gtc.list4_7')}}</li>
			<li>{{$t('gtc.list4_8')}}</li>
		</ol>

		<h2 class="mt-5 mb-3"> {{$t('gtc.title6')}}</h2>
		<ol>
			<li>{{$t('gtc.list5_1')}}</li>
			<li>
				<span>{{$t('gtc.list5_2')}}</span>
				<ul>
					<li>{{$t('gtc.list5_2_1')}}</li>
					<li>{{$t('gtc.list5_2_2')}}</li>
					<li>{{$t('gtc.list5_2_3')}}</li>
				</ul>
				<span>{{$t('gtc.list5_2_summary')}}</span>
			</li>
			<li>{{$t('gtc.list5_3')}}</li>
			<li>{{$t('gtc.list5_4')}}</li>
			<li>{{$t('gtc.list5_5')}}</li>
			<li>{{$t('gtc.list5_6')}}</li>
			<li>{{$t('gtc.list5_7')}}</li>
		</ol>

		<h2 class="mt-5 mb-3">{{$t('gtc.title7')}}</h2>
		<p>{{$t('gtc.content8_1')}} <a href="https://pilotnet.hu/#/privacy-statement">https://pilotnet.hu/#/privacy-statement</a>{{$t('gtc.content8_2')}} <a href="mailto:info@pilotnet.hu">info@pilotnet.hu</a>{{$t('gtc.content8_3')}}</p>

		<h2 class="mt-5 mb-3">{{$t('gtc.title8')}}</h2>
		<ol>
			<li>{{$t('gtc.list6_1_1')}} <a href="mailto:info@pilotnet.hu">info@pilotnet.hu</a>{{$t('gtc.list6_1_2')}}</li>
			<li>{{$t('gtc.list6_2_1')}} <a href="mailto:info@pilotnet.hu">info@pilotnet.hu</a>{{$t('gtc.list6_2_2')}}</li>
			<li>{{$t('gtc.list6_3')}}</li>
		</ol>
	</div>
</template>

<script>
export default {
	name: "GtcView",
	data() {
		return {
			showBackLink: history.length > 1
		}
	}
}
</script>

<style scoped>

</style>
